import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Notfound from './pages/notFound';
import CovidInfo from './pages/covid/covidInfo';
import subDomain from './helper/subDomain';
import styled, {ThemeProvider} from 'styled-components';
import theme from './utils/theme';
import GlobalStyle from './components/styled/global';
import {Navbar} from './components';

window.styled = styled;

let hosts = window.location.host.split('.');
let host = hosts[0];
const subDomainData = subDomain[host]||subDomain.lalbandi;
const routing = (

    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <Router>
        <div>
          <Navbar subDomainData={subDomainData}/>
          <Switch>
            <Route exact path="/"
                   render={props => (
                       <Redirect to="/covid19" />
                   )}/>

            <Route exact path="/covid19"
                   render={props => (
                       <CovidInfo {...props}
                                  subDomainData={subDomainData}/>
                   )}/>
            <Route component={Notfound}/>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
