import React, { Component } from "react";
import CovidInfoService from "../../services/covidInfoService";
import Helper from "../../helper/Helper";
import ContactDetail from "./contactDetail";
import "./sass/covidInfo.scss";
import BannerMessage from "./banner/bannerMessage";
import KoboLink from "./formLinks/koboLink";
import ImportantCenters from "./importantCenters";
import Podcast from "./podcast";
import DonationWrapper from "./donation/donationWrapper";
import DonationLink from "./formLinks/donationLink";
import Activities from "./activities/activities";
import Committee from "./committee/committee";
import Footer from "./footer";

import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";

class CovidInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "np",
      nationalStats: {
        tested_total: 0,
        tested_positive: 0,
        tested_negative: 0,
        total_death: 0,
        total_recovered: 0,
      },
      local_stats: {
        tested_total: 0,
        tested_positive: 0,
        tested_negative: 0,
        total_death: 0,
        total_recovered: 0,
      },
      important_centers: [],
      message_from_mayor: [],
      donor_help: [],
      contact_details: [],
      committee: {},
      podcast_links: [],
      form_links: [],
      major_activities: [],
      ward_representatives: [],
      programs: [],
      centers: {
        isolation_treatment: "आइसाेलेसन केन्द्रहरु",
        quarantine: "क्वारेन्टाइन केन्द्रहरु",
        health_facilities: "स्वास्थ्य केन्द्रहरु",
        pharmacy_medicines: "फार्मेसी / औषधि पसल",
      },
    };
    this.fetchNationalStats = this.fetchNationalStats.bind(this);
    this.fetchMunicipalityData = this.fetchMunicipalityData.bind(this);
    this.getCentersData = this.getCentersData.bind(this);
  }

  fetchNationalStats() {
    CovidInfoService.fetchNationalInfo().then((response) => {
      this.setState({
        nationalStats: this.getStats(response.national_stats),
      });
    });
  }

  fetchMunicipalityData() {
    CovidInfoService.fetchCOVIDInfo(this.props.subDomainData).then(
      (response) => {
        this.setState({
          message_from_mayor: response.message_from_mayor,
          contact_details: response.contact_details,
          form_links: response.form_links,
          local_stats: this.getStats(response.local_stats),
          major_activities: response.major_activities,
          programs: response.programs,
          podcast_links: response.podcast_links,
          ward_representatives: response.ward_representatives,
        });

        Helper.groupArray(response.important_centers, "type").then((result) => {
          this.setState({
            important_centers: result,
          });
        });
        Helper.groupArray(response.donor_help, "type").then((result) => {
          this.setState({
            donor_help: result,
          });
        });
        Helper.groupArray(response.committee, "committee_name_nep").then(
          (result) => {
            this.setState({ committee: result });
          }
        );
      }
    );
  }

  componentWillMount() {
    document.title = `${this.props.subDomainData.title}: Official digital portal for ${this.props.subDomainData.title_eng} `;
    this.fetchNationalStats();
    this.fetchMunicipalityData();
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  getCentersData() {
    let keys = Object.keys(this.state.important_centers);
    const filtered = Object.keys(this.state.centers)
      .filter((key) => keys.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.state.centers[key];
        return obj;
      }, {});
    return filtered;
  }

  existData(data, key) {
    return data[key] !== undefined;
  }

  getStats(list) {
    let sorted = list.sort(Helper.compareValues("date", "desc"));
    return sorted[0];
  }

  componentDidMount() {
    scrollSpy.update();
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollToBottom() {
    scroll.scrollToBottom();
  }

  scrollTo() {
    scroll.scrollTo(100);
  }

  scrollMore() {
    scroll.scrollMore(100);
  }

  render() {
    let subDomainData = this.props.subDomainData;
    let {
      nationalStats,
      podcast_links,
      major_activities,
      contact_details,
      committee,
      ward_representatives,
    } = this.state;
    let localStats = this.state.local_stats;
    let importantCenters = this.state.important_centers;
    let messages = this.state.message_from_mayor;
    let donors = this.state.donor_help;
    let formLinks = this.state.form_links;
    return (
      <div>
        <ContactDetail
          contacts={contact_details}
          wardContacts={ward_representatives}
        />
        <BannerMessage
          message={messages[0]}
          nationalStats={nationalStats}
          subDomainData={subDomainData}
          localStats={localStats}
          formLinks={formLinks}
          centersData={this.getCentersData()}
        />
        <section className="call__action">
          <div className="container">
            {formLinks.length > 0 && (
              <KoboLink formLinks={formLinks} subDomainData={subDomainData} />
            )}
          </div>
        </section>
        <section className="content">
          <div className="container flex">
            <div className="content-left">
              <div className="mun-data" id="data-centers">
                <div>
                  {this.existData(importantCenters, "isolation_treatment") && (
                    <ImportantCenters
                      id="isolation_treatment"
                      importantCenter={importantCenters["isolation_treatment"]}
                      title="आइसाेलेसन केन्द्रहरु"
                      isBed={true}
                      text="आइसाेलेसन"
                      showPersons={true}
                      toolTitle="आइसाेलेसन केन्द्र भन्नाले सरुवा राेगबाट संक्रमित व्यक्तिलाइ अन्य स्वस्थ मानिसहरुकाे सम्पर्कमा जानबाट राेक्नका लागि राखिने छुट्टै स्थान हाे ।"
                    />
                  )}
                  {this.existData(importantCenters, "quarantine") && (
                    <ImportantCenters
                      id="quarantine"
                      importantCenter={importantCenters["quarantine"]}
                      title="क्वारेन्टाइन केन्द्रहरु "
                      isBed={true}
                      text="क्वारंटाइन"
                      showPersons={true}
                      toolTitle="क्वारेन्टाइन केन्द्र भन्नाले कुनै कुनै स्वस्थ व्यक्ति कुनै प्रकारले सरुवा राेग वा राेगीसँग सम्पर्कमा आएका कारण निश्चित अवधीमा उक्त व्यक्तिमा संक्रमण देखिन्छ वा देखिदैँन भनी जाँच गर्नका लागि राखिने छुट्टै स्थान हाे ।"
                    />
                  )}
                  {this.existData(importantCenters, "health_facilities") && (
                    <ImportantCenters
                      id="health_facilities"
                      importantCenter={importantCenters["health_facilities"]}
                      title="स्वास्थ्य केन्द्रहरु"
                    />
                  )}
                  {this.existData(importantCenters, "pharmacy_medicines") && (
                    <ImportantCenters
                      id="pharmacy_medicines"
                      importantCenter={importantCenters["pharmacy_medicines"]}
                      title="फार्मेसी / औषधि पसल"
                    />
                  )}
                </div>
              </div>
              {podcast_links.length > 0 && <Podcast podcasts={podcast_links} />}
              {(donors["Cash"] !== undefined ||
                donors["Materials"] !== undefined) && (
                <section className="donation" id="donor-group">
                  <div>
                    <h2 className="heading__secondary">
                      काेराेना भाइरस (COVID-19) राेकथाममा {subDomainData.title}
                      लाइ प्राप्त सहायता
                    </h2>
                    {donors["Cash"] !== undefined && (
                      <DonationWrapper
                        id="cash-donor"
                        title="नगद सहायता"
                        type="cash"
                        donors={donors["Cash"]}
                      />
                    )}
                    {donors["Materials"] !== undefined && (
                      <DonationWrapper
                        id="material-donor"
                        title="अन्य सामग्री सहायता"
                        type="materials"
                        donors={donors["Materials"]}
                      />
                    )}
                  </div>
                </section>
              )}
              {formLinks.length > 0 && <DonationLink formLinks={formLinks} />}

              {major_activities.length > 0 && (
                <Activities
                  majorActivities={major_activities}
                  subDomainData={subDomainData}
                />
              )}

              {committee !== undefined && Object.keys(committee).length > 0 && (
                <Committee
                  committees={committee}
                  subDomainData={subDomainData}
                />
              )}
            </div>
          </div>
        </section>
        <Footer subDomainData={subDomainData} />
      </div>
    );
  }
}

export default CovidInfo;
