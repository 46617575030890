import React, { Component } from 'react';

class KoboLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let formLinks = this.props.formLinks;
    return (
      <div className="call__action-desc">
        <p>
          यदि तपाइ आफ्नो स्वास्थ्यमा आएका केही परिवर्तन वा लक्षणहरुका बारेमा
          जानकारी गराइ {this.props.subDomainData.title}काे तर्फबाट सहायता
          चाहनुहुन्छ भने
          <a href={formLinks[0].kobo_link} target="_blank" className="link">
            {` `}
            यो फारम भरी पठाउन सक्नुहुनेछ{' '}
          </a>
          । तपाइकाे पहिचान गाेप्य राखिनेछ ।
        </p>
        <a
          href={formLinks[0].kobo_link}
          className="bttn bttn__primary"
          target="_blank"
        >
          स्वयं जाँचको फारम भर्नुहोस्
        </a>
      </div>
    );
  }
}

export default KoboLink;
