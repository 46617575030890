import React, {Component} from 'react';

class CommitteeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      showMoreButton: true,
      tableIsOverFlowing: false,
    };
    this.showRow = this.showRow.bind(this);
    this.showEveryRow = this.showEveryRow.bind(this);
    this.hideEveryRow = this.hideEveryRow.bind(this);
    this.checkTableOverflow = this.checkTableOverflow.bind(this);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    const el = this.tableRef.current;
    this.checkTableOverflow(el);
  }

  checkTableOverflow(el) {
    const isOverFlowing = el.clientWidth + 20 < el.scrollWidth;
    if(isOverFlowing) {
      this.setState({...this.state,tableIsOverFlowing:true})
    }
  }

  showRow(key) {
    if (!this.state.showAll) {
      return key < 3;
    }
    return true;
  }

  showEveryRow() {
    this.setState({
      showAll: true,
      showMoreButton: false,
    });
  }
  hideEveryRow() {
    this.setState({
      showAll: false,
      showMoreButton: true,
    });
  }

  multipleContact(contact) {
    return contact.includes(',');
  }

  splitContact(contact) {
    var numbers = contact.split(',');
    return numbers.map((number, key) => {
      return (
          <>
            <a className="ph__link" key={key} href={'tel:' + number}>
              {number}
            </a>
            <br/>
          </>
      );
    });
  }

  render() {
    let committee = this.props.committee;
    return (
        <div className="table__block">
          <div className="card__title-wrapper">
            <h3 className="heading__secondary">{this.props.title}</h3>
          </div>
          <div className={this.state.tableIsOverFlowing ?  "table__parent-wrap scrolling" : "table__parent-wrap"}>
            <div className="table__parent" ref={this.tableRef}>
            <table className="card card--table">
              <thead>
              <th>नाम</th>
              <th>तह</th>

              <th>सम्पर्क</th>
              </thead>
              <tbody>
              {committee.length > 0 ? (
                  committee.map((member, key) => (
                      <tr key={key}
                          className={this.showRow(key) ? 'show' : 'hide'}>
                        <td>{member.member_name_nep}</td>
                        <td>{member.designation_nep}</td>
                        <td>
                          {this.multipleContact(member.contact_number) ? (
                              this.splitContact(member.contact_number)
                          ) : (
                              <a
                                  className="ph__link"
                                  href={'tel:' + member.contact_number}
                              >
                                {member.contact_number}
                              </a>
                          )}
                        </td>
                      </tr>
                  ))
              ) : (
                  <tr>
                    <td>No Data.</td>
                  </tr>
              )}
              {committee.length > 3 && (
                  <tr>
                    <td>
                      {this.state.showMoreButton ?
                          <button
                              className="btn-add-more"
                              onClick={this.showEveryRow}
                          >
                            + थप हेर्नुहोस ...
                          </button> : (
                              <button
                                  className="btn-add-more"
                                  onClick={this.hideEveryRow}
                              >
                                + कम हेर्नुहोस ...
                              </button>

                          )}
                    </td>
                  </tr>
              )}
              </tbody>
            </table>
            </div>
          </div>
        </div>
    );
  }
}

export default CommitteeDetail;
