import { createGlobalStyle } from 'styled-components';
import reset from './reset';
import modal from './modal';
import base from './base';

const GlobalStyle = createGlobalStyle`
    ${reset};
    ${base};
    ${modal};
`;

export default GlobalStyle;
