import { css } from 'styled-components';
import Imageviewmore from '../../../assets/images/ic_view-more.svg';
import Imagesprite from '../../../assets/images/ic_sprite.svg';
import ImageInfoSprite from '../../../assets/images/ic_sprite-info.svg';
import ImageClose from '../../../assets/images/ic_close.svg';
import PrinterIcon from '../../../assets/images/print.svg';
import DownloadIcon from '../../../assets/images/download.svg';

const base = css`
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&subset=devanagari');

body {
    font: 16px/1.5 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
}

html{
  font-size:14.5px;
}
.big-jumbo{
  font-size: 7.142857142857143rem;
}
.jumbo{
  font-size: 5.142857142857143rem;
}
h1{
  font-size: 2.642857142857143rem;
}
h2{
  font-size: 2rem;
}
h3{
  font-size:1.5rem;
}
h4{
  font-size:1.1428571428571428rem;
}
p{
  font-size:1rem;
}

p {
    margin-bottom: 10px;
    box-sizing:border-box;
}

a {
    color: #014983;
}

button {
    cursor: pointer;
    &:focus {
      outline: 0;
    }
}

h2 {
    color: #F0F8FF;
    font-size: 2.642857142857143rem;
    margin-bottom: 12px;
}

h3 {    
    font-size: 2rem;
}

table {
    width: 100%;
    thead tr {
      border-bottom: 1px solid #3195E7;
    }
    tbody tr {
        border-top: 1px solid #3195E7;
        td {
            padding: 12px;
            &:first-of-type {
              padding-left: 0;  
            }
        }
        &:first-of-type {
          border-top: 0;
        }
    }
    th {
        font-weight: 700;
        padding: 12px;
        text-align: left;
        &:first-of-type {
          padding-left: 0;  
        }
    }
    table tr {
        border: 0;
    }
    strong {
        font-weight: 700;
    }
}

form {
  label {
    color: rgba(0,14,26,0.5);
    display: block;
    font-size: 1rem;
    margin-bottom: 4px;
    text-align: left;
  }
  input {
    box-sizing: border-box;
    &[type="text"],&[type="password"],&[type="email"] {
        background: #F0F8FF;
        border: 1px solid #E5E6E8;
        border-radius: 2px;
        font-size: 1.0714285714285714rem;
        height: 42px;
        margin-bottom: 12px;
        padding: 6px;
        width: 100%;
    }
    &:focus {
      outline: 0;
    }
  }
  textarea {
    background: #F0F8FF;
    border: 1px solid #E5E6E8;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 1.0714285714285714rem;
    height: 80px;
    margin-bottom: 12px;
    padding: 6px;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
}

.btn {
    background: #00529B;
    border: 0;
    border-radius: 30px;
    color: #F0F8FF;
    font-size: 1.1428571428571428rem;
    font-weight: bold;
    height: 60px;
    position: relative;
    width: 356px;
    &:after {
        box-shadow: 0 2px 40px 0 #185688;
        border-radius: 30px;
        bottom: 0;
        content: "";
        height: 60%;
        left: 5%;
        position: absolute;
        width: 90%;
        //z-index: -1;
    }
    &:hover,&:focus {
      background: #3374AF;
      &:after {
        box-shadow: 0 2px 40px 0 #3374AF;
      }
    }
}

.form-group--half {
  display: inline-block;
  width: 238px;
  &:first-of-type {
    margin-right: 5px;
    & + .form-group--half {
      margin-left: 5px;
    }
  }
}

.main-app{
  @media screen {
  margin-top: 60px;    
  }
}

.wrapper nav {
  display: block;
}


.component__type {
    color: #F0F8FF;
    height: 210px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 64%;
    h2 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 36px;
        margin-bottom: 20px;
    }
    .view--more {
        bottom: 0;
        filter: brightness(0) invert(1);
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        background-position: right 1px;
        padding-right: 40px;
        text-indent: 1px;
        width: auto;
        white-space: nowrap;
        font-size: 1rem;
    }
}   

.view--more {
    background: url(${Imageviewmore}) no-repeat; 
    display: inline-block;
    height: 29px;
    text-indent: -9999px;
    width: 29px;
}

.view--more--info {
    color: #014983;
    font-size: 1.1428571428571428rem;
    padding-right: 40px;
    position: relative;
    text-decoration: none;
    &:after {
         background: url(${Imageviewmore}) no-repeat 0 -28px; 
         content: "";
         height: 29px;
         right: 0;
         position: absolute;
         top: 0;
         width: 29px;
    }
}

.download {
    color: #F0F8FF;
    position: relative;
    text-decoration: none;
    &:before {
        background: url(${Imageviewmore}) no-repeat 0 0; 
         content: "";
         filter: brightness(0) invert(1);
         height: 29px;
         left: -35px;
         position: absolute;
         top: -4px;
         transform: rotate(90deg);
         width: 29px;
    }
}

.external-link {
    display: block;
    background: url(${Imagesprite}) no-repeat 0 -36px; 
    content: "";
    height: 24px;
    position: absolute;
    right: -28px;
    text-indent: -9999px;
    top: 14px;
    width: 24px;
}

.print {
    display: inline-block;
    vertical-align: middle;
    color: #F0F8FF;
    position: relative;
    &:before {
        background: url(${Imagesprite}) no-repeat 0 0; 
         content: "";
         height: 24px;
         left: -30px;
         position: absolute;
         top: 2px;
         width: 24px;
    }
}

.info {
  position:relative;
  &:before {
    background: url(${Imagesprite}) no-repeat 0 -88px;
    content: " ";
    height: 24px;
    left: 0;
    position: absolute;
    top: -3px;
    width: 24px;
  }
}

.social-links {
    margin-top: -200px;
    > div {
        position: relative;
    }
    > div > div > div {
        background: url(${Imagesprite}) no-repeat 0 0;
        cursor: pointer;
        display: inline-block;
        height: 36px;
        text-indent: -9999px;
        width: 36px;
        &.facebook {
            background-position: -84px 0px;
        }
        &.twitter {
            background-position: -84px -50px;
        }
        &.website {
            background-position: -84px -100px;
        }
        &:focus {
          outline: 0;
        }
    }
    .website {
        background: url(${Imagesprite}) no-repeat -84px -100px;
        cursor: pointer;
        display: inline-block;
        height: 36px;
        text-indent: -9999px;
        width: 36px;
    }
    .copy {
      background: #fff;
      border-radius: 4px;
      bottom: -30px;
      color: #000;
      font-size: 0.8571428571428571rem;
      opacity: 0;
      padding: 2px 5px;
      position: absolute;
      right: 0;
    }
    > div > div {
      position: relative;
      &:hover {
        .copy {
          opacity: 1;
        }
      }
    }
}

.list__wrap {
    h3 {
        margin-bottom: 12px;
    }
    ul,ol {
        padding-left: 20px;
        li {
            list-style-type: disc;
        }
    }
}

.component__wrap {
  border-bottom: 1px solid rgba(240,248,255,0.2);
  color: #F0F8FF;
  display: block;
  min-height: 312px;
  text-decoration: none;
  > div {
    min-height: 202px;
  }
}

.mun-voice__wrap {
  > div {
    width: 100%;
  }
  .component__type {
    padding-bottom: 16px;
    width: 38%;
  }
  .component__wrap {
    border-bottom: 0;
  }
}

.eprofile__block--home {
  background: #028EFF;
}

.digitisation__block--home {
  background: #00529B;
}

.digitisation__content {


.mun-title-print, .selected-year-print{
  @media screen {
    display: none;
  }
}

  .mun-title-print {
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 12px;
    }

     .selected-year-print {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #000 !important;
  }

   .mun-detail-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(.program-filters){
      margin-bottom: 22px;

    }
   
    @media print{
      display: none;
      }
    }

      .print-download-options{
        display: flex;
        
        .download,.print{
          margin-left: 60px;
          cursor: pointer;
          @media only screen and (max-width: 767px) {
          text-indent: -9999px;
          margin-left: 0;
        }

        }
        .download {
          &:before {
            background: url(${DownloadIcon}) no-repeat;
            transform: none;
            top: 3px;
            left: -28px;
          }
        }

        .print {
          &:before {
            background: url(${PrinterIcon}) no-repeat;
            background-size: 20px;
          }
        }

         @media only screen and (max-width: 767px) {
           margin-right: -46px;
         }
    }


    .select2-container {
      /* margin-bottom: 22px; */
      width: 156px!important;
      &:focus {
        outline: 0;
      }
    }
    .select2-container--default {
        .select2-selection--single {
          background: rgba(1,73,131,0.4);
          border: 1px solid rgba(255,255,255,0.4);
          border-radius: 18px!important;
          height: 36px;
          &:focus {
            outline: 0;
          }
          .select2-selection__rendered {
              color: #F0F8FF;
              font-size: 1rem;
              line-height: 36px;
              padding-left: 14px;
          }
          .select2-selection__placeholder {
              color: #F0F8FF;
            
          }
          .select2-selection__arrow {
            height: 36px;
            right: 12px;
            b {
              border-top-color: #F0F8FF;
              border-width: 7px 6px 0 6px;
              margin-top: -3px;
          }
          }
        }
        &.select2-container--open.select2-container--below .select2-selection--single:focus {
          outline: 0;
        }   
    } 
    .select2-container--open {
    .select2-selection {
          background: #014983;
          border-color: #fff;
         }
    .select2-dropdown {
          background: transparent;
          border: 0;
          border-radius: 6px;
         }
    }


    @media print{
      button, a{
        display: none !important;
      }
    }

}

.select2-container--open .select2-dropdown--below {
  background: #F0F8FF;
  border: 0;
  border-radius: 6px!important;
  box-shadow: 0 2px 10px rgba(1, 73, 131, 0.28);
  margin-top: 6px;
  @media only screen and (min-width:768px){
      width: 212px!important;
  }
  @media only screen and (max-width:768px){
    .select2-results__options{
      max-height:160px;
    }
  }
}

.year-filter+span{
  .select2-selection__rendered:before{
    content: 'आ. व. '
  }
}

[id*='२०'].select2-results__option, [id*='20'].select2-results__option  {
  &:before{
    content: 'आ. व. ';
  }
}


.select2-results {
  padding: 10px 0;
}

.select2-results__message {
  font-size: 1rem;
}

.select2-results > .select2-results__options {
  background: transparent !important;
  border-radius: 6px;
  li {
    border-bottom: 1px solid rgba(1,73,131,0.1);
    margin: 0 38px 10px;
    padding: 0 0 10px;
    &:last-child {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    @media only screen and (max-width:768px){
      margin: 0 10px 10px;
    }
  }
}



.select2-container--default .select2-results__option[aria-selected=true]{background-color:transparent !important}


.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: none!important;
  color: #000E1A!important;
  position:relative;
  /* &:before {
   background: #3195E7;
   border-radius: 50%;
   display: none;
   height: 8px;
   left: -23px;
   position: absolute;
   top: 9px;
   width: 8px;
  } */
}



.tooltip-inner {
  background: #F0F8FF;
  border-radius: 8px;
  min-width: 240px;
  position:relative;
  > div {
      background: #F0F8FF;
      border-radius: 8px;
      padding: 22px 16px 15px;
      position: relative;
      z-index: 1;
  }
  
  &:after {    
    box-shadow: 0 0 60px 0 rgba(0,0,0,0.50);
    bottom: 0;
    content: "";
    height: 29%;
    left: 9%;
    position: absolute;
    width: 84%;
  }
  h3 {
      border-bottom: 1px solid #D8D8D8;
      color: #59626A;
      font-size: 1.7142857142857142rem;
      line-height: 32px;
      padding-bottom: 7px;
      margin-bottom: 15px;
  }
  .budget,.programme {
    color: #999FA3;
    font-size: 1rem;
    margin-bottom: 10px;
    span {
      color: #000E1A;
      font-size: 2rem;
      font-weight: 700;
    }
  }
  .budget span {
    display: block;
    i {
      color: #999FA3;
      font-weight: normal;
    }
  }
  @media only screen and (max-width: 767px){
    min-width:150px;
    h3{
      font-size: 1.1428571428571428rem !important;
      padding-bottom:0;
      margin-bottom:5px;
      line-height:1.7142857142857142rem;
    }
    .budget,.programme{
        span{
          font-size:1.2857142857142858rem;
        }
        margin-bottom:5px;
    }
  }
}
.select-reset {
  background: #F0F8FF!important;
  color: #20598e!important;
  font-size: 1rem;
  font-weight: bold;
}

 .sidebar {
    border-left: 1px solid rgba(240,248,255,0.2);
    height: 100vh;
    width: 100%;
    > div:first-of-type {
        flex-wrap: wrap;
        > div {
            border-bottom: 1px solid rgba(240,248,255,0.2);
            font-size: 1.1428571428571428rem;
            height: 114px;
            transition: background 0.3s ease-in-out;
            width: 100%;
            &:hover {
              background: #0a508a;
            }
        }
       }
    [class*="box--"] {
        box-sizing: border-box;
        color: #F0F8FF;
        display: block;
        font-size: 1.5rem;
        height: 264px;
        line-height: 28px;
        padding: 168px 24px 10px 45px;!important;
        position: relative;
        text-decoration: none;
        &:before {
            background: url(${ImageInfoSprite}) no-repeat 0 0;
            content: "";
            height: 74px;
            left: 45px;
            position: absolute;
            right: auto;
            top: 84px;
            width: 74px;
        }
    }
    .box--policies:before {
        background-position: -94px 0;
    }
    .box--programme:before {
        background-position: 0 -94px;
    }
    .box--info:before {
        background-position: -94px -94px;
    }
    .social-links {
        bottom: 44px;
        margin: 0;
        right: 50px;
        position: absolute;
        > div {
            border: 0!important;
            height: auto!important;
            justify-content: space-between;
            width: 136px!important;
        }
    }
     > div:first-of-type > div {
        height: 264px;  
        width: 50%;
        &:nth-child(odd) {
            border-right: 1px solid rgba(240,248,255,0.2);
        }
    }
  }
  
  .sidebar--home {
    background: rgba(1,73,131,0.9);
    height: initial;
    > div:first-of-type > div{
      text-align: center;
      &:nth-last-child(-n+2) {
      border-bottom: 0;
    }
    }
    [class*="box--"] {
      padding: 148px 45px 30px 45px;
      &:before {
          left: 50%;
          top: 64px;
          transform: translateX(-50%);
    }
  }
 }
 
 .search-input::-webkit-search-cancel-button {
    background: url(${ImageClose}) no-repeat right;
    color: #00529b;
    height: 28px;
    position:relative;
    right: 16px;
    width: 28px;
    -webkit-appearance: none;
 }
 
 .digitisation__content--left {
    padding: 90px 80px;
  }
  
  .content__empty {
    box-shadow: rgba(1, 73, 131, 0.9) 0px 2px 40px 0px;
    background: rgba(240, 248, 255, 0.9);
    border-radius: 8px;
    font-size: 1.4285714285714286rem;
    padding: 42px;
  }
  
  .video-block {
      a {
          align-items:  center;
          border-radius: 4px;
          display: flex;
          max-height: 270px;
          max-width: 474px;
          margin: 0 auto;
          position:relative;
          overflow: hidden;
          &:before,&:after {
            content: "";
            position:absolute;
          }
          &:before {
            background: rgba(59,55,56,0.9);
            border-radius: 10px;
            height: 50px; 
            left: 50%;
            top: 50%;
            width: 68px;
            transform: translate(-50%,-50%);
          }
          &:after {
            border-left: 16px solid #fff;
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            left: 50.5%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
          &:hover {
            &:before {
              background: #FF0000;
            }
          }
      }
  }
  
  .error__wrap {
    border-radius: 4px;
    font-size: 0.9285714285714286rem;
    text-align: left;
    li {    
      background: #fde2ec;
      border-radius: 2px;
      color: #f37ca6;
      padding: 6px 10px;
      & + li {
        margin-top: 2px;
      }
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
  
  [data-tooltip] {
  position: relative;
}
[data-tooltip]:before, [data-tooltip]:after {
  opacity: 0;
  position: absolute;
  left: 50%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
[data-tooltip]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-bottom-width: 0;
  content: '';
  top: -2px;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, calc(-50% - 6px));
          transform: translate(-50%, calc(-50% - 6px));
  transition: opacity 0.1s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  z-index: 110000;
}
[data-tooltip]:after {
  content: attr(data-tooltip);
  text-align: center;
  padding: 9px 10px 9px;
  font-size: 1rem;
  font-weight: normal;
  border-radius: 8px;
  color: #333;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1), transform 0.3s cubic-bezier(0.73, 0.01, 0, 1), -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1);
  pointer-events: none;
  z-index: 999;
  white-space: nowrap;
  bottom: 100%;
  -webkit-transform: translate(-50%, 12px);
          transform: translate(-50%, 12px);
  max-width: 320px;
  text-overflow: ellipsis;
  overflow: hidden;
}

[data-tooltip]:after {
  background: #fff;
}

[data-tooltip]:before {
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
}

[data-tooltip]:focus:before, [data-tooltip]:focus:after, [data-tooltip]:hover:before, [data-tooltip]:hover:after {
  opacity: 1;
}
[data-tooltip]:focus:before, [data-tooltip]:hover:before {
  transition: opacity 0.1s cubic-bezier(0.73, 0.01, 0, 1) 0.1s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.1s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.1s;
  -webkit-transform: translate(-50%, calc(-50% - 2px));
          transform: translate(-50%, calc(-50% - 2px));
}
[data-tooltip]:focus:after, [data-tooltip]:hover:after {
  -webkit-transform: translate(-50%, -6px);
          transform: translate(-50%, -6px);
}

[data-tooltip][data-tooltip-conf*=right]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-left-width: 0;
  left: auto;
  right: -6px;
  top: 50%;
  -webkit-transform: translate(calc(-50% + 7px), -50%);
          transform: translate(calc(-50% + 7px), -50%);
}

[data-tooltip][data-tooltip-conf*=right]:after {
  top: 50%;
  left: 100%;
  bottom: auto;
  -webkit-transform: translate(-12px, -50%);
  transform: translate(-12px, -50%);
}

[data-tooltip][data-tooltip-conf*=right]:focus:before,
[data-tooltip][data-tooltip-conf*=right]:hover:before {
  -webkit-transform: translate(calc(-50% + 3px), -50%);
  transform: translate(calc(-50% + 3px), -50%);
}

[data-tooltip][data-tooltip-conf*=right]:focus:after,
[data-tooltip][data-tooltip-conf*=right]:hover:after {
  -webkit-transform: translate(7px, -50%);
  transform: translate(7px, -50%);
}

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-top-width: 0;
  top: auto;
  bottom: -6px;
  left: 50%;
  -webkit-transform: translate(-50%, calc(-50% + 6px));
  transform: translate(-50%, calc(-50% + 6px));
}

[data-tooltip][data-tooltip-conf*=bottom]:after {
  top: 100%;
  left: 50%;
  bottom: auto;
  -webkit-transform: translate(-50%, -12px);
  transform: translate(-50%, -12px);
}

[data-tooltip][data-tooltip-conf*=bottom]:focus:before,
[data-tooltip][data-tooltip-conf*=bottom]:hover:before {
  -webkit-transform: translate(-50%, calc(-50% + 2px));
  transform: translate(-50%, calc(-50% + 2px));
}

[data-tooltip][data-tooltip-conf*=bottom]:focus:after,
[data-tooltip][data-tooltip-conf*=bottom]:hover:after {
  -webkit-transform: translate(-50%, 6px);
  transform: translate(-50%, 6px);
}

[data-tooltip][data-tooltip-conf*=left]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 0;
  left: -2px;
  top: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(calc(-50% - 8px), -50%);
  transform: translate(calc(-50% - 8px), -50%);
}

[data-tooltip][data-tooltip-conf*=left]:after {
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  -webkit-transform: translate(12px, -50%);
  transform: translate(12px, -50%);
}

[data-tooltip][data-tooltip-conf*=left]:focus:after,
[data-tooltip][data-tooltip-conf*=left]:hover:after {
  -webkit-transform: translate(-7px, -50%);
  transform: translate(-7px, -50%);
}

[data-tooltip][data-tooltip-conf*=left]:focus:before,
[data-tooltip][data-tooltip-conf*=left]:hover:before {
  -webkit-transform: translate(calc(-50% - 3px), -50%);
  transform: translate(calc(-50% - 3px), -50%);
}

[data-tooltip][data-tooltip-conf*=multiline]:after {
  word-break: break-word;
  white-space: normal;
  min-width: 180px;
  text-overflow: clip;
}

[data-tooltip][data-tooltip-conf*=delay]:before {
  -webkit-transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
}

[data-tooltip][data-tooltip-conf*=delay]:after {
  -webkit-transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
}

[data-tooltip][data-tooltip-conf*=delay]:focus:before,
[data-tooltip][data-tooltip-conf*=delay]:hover:before {
  -webkit-transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
}

[data-tooltip][data-tooltip-conf*=delay]:focus:after,
[data-tooltip][data-tooltip-conf*=delay]:hover:after {
  -webkit-transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
}

[data-tooltip][data-tooltip-conf*=shadow]:after {
  -webkit-box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
}

[data-tooltip][data-tooltip-conf*=no-fading]:after,
[data-tooltip][data-tooltip-conf*=no-fading]:before {
  -webkit-transition: none;
  transition: none;
}

[data-tooltip][data-tooltip-conf*=no-arrow]:before {
  display: none;
}

[data-tooltip][data-tooltip-conf*=square]:after {
  border-radius: 0;
}

[data-tooltip][data-tooltip-conf*=invert]:after {
  color: #fff;
  background: #ccc;
}

[data-tooltip][data-tooltip-conf*=invert]:before {
  border-top-color: #ccc;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #ccc;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #ccc;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #ccc;
}

[data-tooltip][data-tooltip-conf*=success]:after {
  background: #8bc34a;
}

[data-tooltip][data-tooltip-conf*=success]:before {
  border-top-color: #8bc34a;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #8bc34a;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #8bc34a;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #8bc34a;
}

[data-tooltip][data-tooltip-conf*=info]:after {
  background: #29d2e4;
}

[data-tooltip][data-tooltip-conf*=info]:before {
  border-top-color: #29d2e4;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #29d2e4;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #29d2e4;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #29d2e4;
}

[data-tooltip][data-tooltip-conf*=warning]:after {
  background: #f87d09;
}

[data-tooltip][data-tooltip-conf*=warning]:before {
  border-top-color: #f87d09;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #f87d09;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #f87d09;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #f87d09;
}

[data-tooltip][data-tooltip-conf*=danger]:after {
  background: #e91e63;
}

[data-tooltip][data-tooltip-conf*=danger]:before {
  border-top-color: #e91e63;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #e91e63;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #e91e63;
  border-left-color: transparent;
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #e91e63;
}

[data-tooltip][data-tooltip='']:after, [data-tooltip][data-tooltip='']:before {
  display: none;
}

.hide{
  display:none;
}

.ReactModal__Overlay{
	z-index: 9999999 !important;
}

#g-recaptcha{
  position: relative;
}


`;

export default base;
