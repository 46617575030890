import React, { Component } from "react";
import YoutubeVideo from "./youtubeVideo";
import Svg from "./../svg";
import StatsBar from "./StatsBar";
import { Link } from "react-scroll";

class BannerMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      videoLink: "",
      showDropDown: false,
      showNav: false,
      imageLink: "",
    };
    this.getVideoId = this.getVideoId.bind(this);
    this.showDropDownMenu = this.showDropDownMenu.bind(this);
    this.closeDropDownMenu = this.closeDropDownMenu.bind(this);
    this.showNavMenu = this.showNavMenu.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      videoLink:
        nextProps.message !== undefined ? nextProps.message.video_link : "",
      imageLink:
        nextProps.message !== undefined ? nextProps.message.image_link : "",
    });
  }

  getVideoId() {
    if (this.state.videoLink !== "") {
      let url = new URL(this.state.videoLink);
      return url.searchParams.get("v");
    }
    return "";
  }

  showDropDownMenu() {
    this.setState({...this.state, showDropDown: !this.state.showDropDown });
  }

  showNavMenu() {
    this.setState({ ...this.state, showNav: !this.state.showNav }, state => {
      if (this.state.showNav) {
        document.body.classList.add('mobile-nav--visible');
      }else{
        document.body.classList.remove('mobile-nav--visible');
      }
    });
  }

  closeDropDownMenu() {
    document.body.classList.remove('mobile-nav--visible');
    this.setState({ ...this.state, showDropDown: false, showNav: false });
  }


  getTitle(domain) {
    if (domain.title_eng === "dhangadhi") {
      return "सुदूरपश्चिम प्रदेश ";
    }

    return `${domain.title} ${domain.municipality}`;
  }

  render() {
    let {
      nationalStats,
      localStats,
      subDomainData,
      message,
      formLinks,
      centersData,
    } = this.props;
    let videoDetail = {
      url: this.state.videoLink,
      video_id: this.getVideoId(),
    };
    return (
      <section className="banner">
        <div className="section__nav">
          <ul className="section__nav-menu section__nav-menu--desktop">
            <li
              className={
                this.state.showDropDown
                  ? "section__nav-item section__nav-menu-container  section__nav-item--show"
                  : "section__nav-item section__nav-menu-container  "
              }
            >
              <span
                className="section__nav-link-layer"
                onClick={this.showDropDownMenu}
              ></span>
              <Link
                href="#"
                className="section__nav-link"
                to="data-centers"
                spy={true}
                smooth={true}
                activeClass="active--link"
              >
                महत्वपुर्ण केन्द्रहरु
              <Svg img="cheveron-up"/>
              </Link>
              <ul className="section__nav-nested-menu">
                {Object.keys(centersData).map((textKey, key) => (
                  <li className="section__nav-nested-item" key={key}>
                    <Link
                      href="#"
                      onClick={this.showDropDownMenu}
                      className="section__nav-nested-link"
                      to={textKey}
                      spy={true}
                      smooth={true}
                      activeClass="active--link--nested"
                    >
                      {centersData[textKey]}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="section__nav-item">
              <Link
                href="#"
                onClick={this.closeDropDownMenu}
                className="section__nav-link"
                to="podcasts"
                spy={true}
                smooth={true}
                activeClass="active--link"
              >
                पोडकाष्टहरु
              </Link>
            </li>
            <li className="section__nav-item">
              <Link
                href="#"
                onClick={this.closeDropDownMenu}
                className="section__nav-link"
                to="donor-group"
                spy={true}
                smooth={true}
                activeClass="active--link"
              >
                प्राप्त सहायता
              </Link>
            </li>
            <li className="section__nav-item">
              <Link
                href="#"
                onClick={this.closeDropDownMenu}
                className="section__nav-link"
                to="activities"
                spy={true}
                smooth={true}
                activeClass="active--link"
              >
                मुख्य गतिविधिहरू
              </Link>
            </li>
            <li className="section__nav-item">
              <Link
                href="#"
                onClick={this.closeDropDownMenu}
                className="section__nav-link"
                to="committee"
                spy={true}
                smooth={true}
                activeClass="active--link"
              >
                COVID-19 समिति
              </Link>
            </li>
          </ul>

          <div
            className={
              this.state.showNav
                ? `mobile__nav mobile__nav--visible`
                : `mobile__nav  `
            }
          >
            <div className="mobile__nav-bottom-block">
            <a
              onTouchStart={this.showNavMenu}
              className="dropdown__trigger"
            >
              <span className={"burger__icon"}>
                <span className={"burger__icon-line"}></span>
                <span className={"burger__icon-line"}></span>
                <span className={"burger__icon-line"}></span>
              </span>
              मेन्यु
            </a>
              <ul className="section__nav-menu">
                {formLinks.length > 0 && (
                    <li>
                      <a
                          href={formLinks[0].kobo_link}
                          target="_blank"
                          className="section__nav-link section__nav-link-test"
                      >
                        स्वयं जाँच
                      </a>
                    </li>
                )}
                {formLinks.length > 0 && (
                    <li>
                      <a
                          href={formLinks[0].donation_link}
                          target="_blank"
                          className="section__nav-link section__nav-link-donate"
                      >
                        सहायता सम्पर्क
                      </a>
                    </li>
                )}
              </ul>
            </div>
            <div className="mobile__nav-container">
              <ul className="mobile__nav-list">
                <li className="mobile__nav-item">
                  <Link
                      onClick={this.closeDropDownMenu}
                    className="mobile__nav-link"
                    to="data-centers"
                    spy={true}
                    smooth={true}
                    activeClass="active--link"
                  >
                    महत्वपुर्ण केन्द्रहरु
                  </Link>
                  <ul className="mobile__nav-nested-menu">
                    {Object.keys(centersData).map((textKey, key) => (
                      <li className="mobile__nav-nested-item" key={key}>
                        <Link
                          onClick={this.closeDropDownMenu}
                          className="mobile__nav-link"
                          to={textKey}
                          spy={true}
                          smooth={true}
                          activeClass="active--link"
                        >
                          {centersData[textKey]}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mobile__nav-item">
                  <Link
                    onClick={this.closeDropDownMenu}
                    className="mobile__nav-link"
                    to="podcasts"
                    spy={true}
                    smooth={true}
                    activeClass="active--link"
                  >
                    पोडकाष्टहरु
                  </Link>
                </li>
                <li className="mobile__nav-item">
                  <Link
                    onClick={this.closeDropDownMenu}
                    className="mobile__nav-link"
                    to="donor-group"
                    spy={true}
                    smooth={true}
                    activeClass="active--link"
                  >
                    प्राप्त सहायता
                  </Link>
                </li>
                <li className="mobile__nav-item">
                  <Link
                    onClick={this.closeDropDownMenu}
                    className="mobile__nav-link"
                    to="activities"
                    spy={true}
                    smooth={true}
                    activeClass="active--link"
                  >
                    मुख्य गतिविधिहरू
                  </Link>
                </li>
                <li className="mobile__nav-item">
                  <Link
                    onClick={this.closeDropDownMenu}
                    className="mobile__nav-link"
                    to="committee"
                    spy={true}
                    smooth={true}
                    activeClass="active--link"
                  >
                    COVID-19 समिति
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <ul className="section__nav-menu section__nav-menu--desktop">
            {formLinks.length > 0 && (
              <li>
                <a
                  href={formLinks[0].kobo_link}
                  target="_blank"
                  className="section__nav-link section__nav-link-test"
                >
                  स्वयं जाँच
                </a>
              </li>
            )}
            {formLinks.length > 0 && (
              <li>
                <a
                  href={formLinks[0].donation_link}
                  target="_blank"
                  className="section__nav-link section__nav-link-donate"
                >
                  सहायता सम्पर्क
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="container">
          <div className="banner__header justify-between">
            <h3 className="banner__header-title">
              COVID-19 तथ्यचित्र
              <small>१ घण्टा अघी अद्यावधिक गरिएको</small>
            </h3>
            {/*<div className="lg-switcher">*/}
            {/*<span className="lg-toggle"/>*/}
            {/*<button>en</button>*/}
            {/*<button>ने</button>*/}
            {/*</div>*/}
          </div>
          <div className="banner__content">
            <div className="banner__content-inner">
              <div className="banner__heading-wrap">
                <h1 className="banner__heading">
                  काेराेना भाइरस (COVID-19) राेकथाममा {subDomainData.title}{" "}
                  {subDomainData.municipality}काे अभियान
                </h1>
                <span className="banner__heading-small-text">
                  {this.getTitle(subDomainData)}को COVID-19 सम्बन्धी अवस्था
                </span>
              </div>
              <div className="banner__stats">
                <div className="banner__stats-card-wrap  row">
                  <div className="banner__virus">
                    <Svg img="corona-large" />
                  </div>
                  <div className="banner__virus-green">
                    <Svg img="corona-green" />
                  </div>
                  <StatsBar
                    nationalStats={nationalStats}
                    localStats={localStats}
                  />
                </div>
              </div>
              <div>
                <YoutubeVideo
                  videoDetail={videoDetail}
                  imageLink={this.state.imageLink}
                  message={message}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BannerMessage;
