import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { flex, media } from "../styled/utils";
import { rgba } from "../utils";
import { images } from "../../config";
import Contact from "../contact";
import SocialMobile from "../social-mobile";
import FontChanger from "../fontChanger";
import onClickOutside from "react-onclickoutside";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
      theme: null,
    };

    this.selectedMun = this.props.subDomainData.api_id;
    this.url = this.props.subDomainData.url;
    this.title = this.props.subDomainData.title;
  }

  componentDidMount() {
    this.onRouteChanged();
  }

  handleClickOutside = () => {
    if (this.state.isDropdownOpen) {
      this.setState({
        isDropdownOpen: false,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    if (this.props.location.pathname.includes("badapatra")) {
      this.setState({
        theme: "purple",
      });
    } else {
      this.setState({
        theme: null,
      });
    }
  }

  render() {
    const { subDomainData } = this.props;
    const { isDropdownOpen, theme } = this.state;
    const hide = {
      display: "none",
    };

    return (
      <Content>
        <div className={theme ? "global-bar purple" : "global-bar"}>
          <div className="nav">
            <NavLink
              to="/"
              className="content-left"
              onClick={this.handleClickOutside}
            >
              <img
                src={images.governmentLogo}
                alt="Government logo"
                className="gov-logo"
              />
              <span>{subDomainData.title}</span>
              <span>{subDomainData.municipality}</span>
            </NavLink>
            <div className="content-right">
              {/*<Contact*/}
              {/*    onToggleContact={this.handleClickOutside}*/}
              {/*    selectedMun={this.selectedMun}*/}
              {/*    url={this.url}*/}
              {/*    title={this.title}*/}
              {/*    contact={subDomainData.contact}*/}
              {/*/>*/}
              <SocialMobile />
              <FontChanger />
            </div>
          </div>
        </div>
        {/* <div className={`overlay ${isDropdownOpen ? 'visible' : ''}`} /> */}
      </Content>
    );
  }
}

export default withRouter(onClickOutside(Navbar));

const Content = styled.div``;
