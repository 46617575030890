import React, { Component } from "react";
import Modal from "../covidModal";

class ActivityDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.setState({
      show: true,
    });
  }

  closeModal() {
    this.setState({
      show: false,
    });
  }

  getPhotoId(link) {
    if (link !== "") {
      let url = new URL(link);
      return url.searchParams.get("id");
    }
    return "";
  }

  getPhotoUrl(activity) {
    if (activity.notes !== "drive") {
      return activity.image_url;
    }
    let id = this.getPhotoId(activity.image_url);

    return `https://drive.google.com/uc?export=view&id=${id}`;
  }

  render() {
    let activity = this.props.activity;
    return (
      <div className={`card programme__card ${this.props.classHide}`}>
        {activity.notes !== "invalid" && activity.image_url !== "" && (
          <a href={activity.image_url} target="_blank">
            <img className="card__img" src={this.getPhotoUrl(activity)} />
          </a>
        )}
        <h2 className="heading__primary card__heading">{activity.title_nep}</h2>
        <p className="card__paragraph activity-description">
          {activity.description_nep.substring(0, 150)}
        </p>
        <button className="btn-read-more" onClick={this.showModal}>
          थप पढ्नुहोस् ...
        </button>
        <Modal
          isOpen={this.state.show}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.closeModal}
          className="modal__activity"
        >
          <div className="activity-modal">
            {activity.notes !== "invalid" && activity.image_url !== "" && (
              <a href={activity.image_url} target="_blank">
                <img className="card__img" src={this.getPhotoUrl(activity)} />
              </a>
            )}
            <h2 className="heading__primary card__heading">
              {activity.title_nep}
            </h2>
            <button className="modal__close" onClick={this.closeModal} />
            <p className="card__paragraph ">{activity.description_nep}</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ActivityDetail;
