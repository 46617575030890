import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="footer">
        <div className="container">
          <p className="desc">
            याे तथ्यचित्र {this.props.subDomainData.title}{" "}
            {this.props.subDomainData.municipality}का लागि सेसी SUSASAN परियाेजनाकाे सहकार्यमा विकास
            गरिएकाे हाे । यससम्बन्धमा तपाइका कुनै प्रतिकृया भएमा{" "}
            <a className="link" href="mailto: support@susasan.org">
              support@susasan.org
            </a>{" "}
            मा पठाउन सक्नुहुनेछ ।
          </p>
        </div>
      </section>
    );
  }
}
export default Footer;
