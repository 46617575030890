import React, { Component } from "react";
import ActivityDetail from "./activityDetail";

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      showMoreButton: true,
    };
    this.showRow = this.showRow.bind(this);
    this.showEveryRow = this.showEveryRow.bind(this);
    this.hideEveryRow = this.hideEveryRow.bind(this);
  }
  showRow(key) {
    if (!this.state.showAll) {
      return key < 3;
    }
    return true;
  }

  showEveryRow() {
    this.setState({
      showAll: true,
      showMoreButton: false,
    });
  }
  hideEveryRow() {
    this.setState({
      showAll: false,
      showMoreButton: true,
    });
  }
  render() {
    let activities = this.props.majorActivities;
    return (
      <section className="programme" id="activities">
        <h2 className="heading__primary section--heading">
          काेराेना भाइरस (COVID-19) राेकथामका लागि{" "}
          {this.props.subDomainData.title}काे मुख्य गतिविधिहरू
        </h2>
        {activities !== undefined && (
            <>
              <div className="card__wrapper flex row">
                {activities.map((activity, key) => (
                    <ActivityDetail
                        activity={activity}
                        key={key}
                        classHide={this.showRow(key) ? 'show' : 'hide'}
                    />
                ))}
              </div>
              {
                activities.length > 3 && (
                    this.state.showMoreButton ?
                        (
                            <button className="btn-add-more"
                                    onClick={this.showEveryRow}>
                              + थप हेर्नुहोस ...
                            </button>
                        ) :
                        (<button className="btn-add-more"
                                 onClick={this.hideEveryRow}>
                          + कम हेर्नुहोस ...
                        </button>))
              }
            </>
        )}
      </section>
    );
  }
}

export default Activities;
