import React, {Component} from 'react';
import Helper from '../../helper/Helper';
import Svg from "./svg";


class ImportantCenters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      showMoreButton: true,
      tableIsOverFlowing: false,
    };
    this.showRow = this.showRow.bind(this);
    this.showEveryRow = this.showEveryRow.bind(this);
    this.hideEveryRow = this.hideEveryRow.bind(this);
    this.checkTableOverflow = this.checkTableOverflow.bind(this);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    const el = this.tableRef.current;
    this.checkTableOverflow(el);
  }

  checkTableOverflow(el) {
    const isOverFlowing = el.clientWidth + 20 < el.scrollWidth;
    if(isOverFlowing) {
      this.setState({...this.state,tableIsOverFlowing:true})
    }
  }

  showRow(key) {
    if (!this.state.showAll) {
      return key < 3;
    }
    return true;
  }

  showEveryRow() {
    this.setState({
      showAll: true,
      showMoreButton: false,
    });
  }

  hideEveryRow() {
    this.setState({
      showAll: false,
      showMoreButton: true,
    });
  }

  multipleContact(contact) {
    return contact.includes(',');
  }

  splitContact(contact) {
    var numbers = contact.split(',');
    return numbers.map((number, key, arr) => {
      return (
          <>
            <a className="ph__link" key={key} href={'tel:' + number}>
              {number}
            </a>
            {key + 1 !== arr.length ?<span className="ph__link-comma">,</span> : ``}
          </>
      );
    });
  }

  render() {
    let centers = this.props.importantCenter ?? [];
    return (
        <div className="table__block" id={this.props.id}>
          <div className="card__title-wrapper">
            <h3 className="heading__secondary">{this.props.title}</h3>
            {this.props.isBed && (
                <div className="note-text">
                  <a href="javascript:;" className="link">
                    के हो यो?
                  </a>
                  <div className="note-hover-content">
                    <span className="hover-arrow"></span>
                    <p>{this.props.toolTitle}</p>
                  </div>
                </div>
            )}
          </div>
          <div className={this.state.tableIsOverFlowing ?  "table__parent-wrap scrolling" : "table__parent-wrap"}>
            <div className="table__parent" ref={this.tableRef}>
               <table className="card card--table">
                <thead>
                  <th>नाम</th>
                  <th>ठेगाना / नक्शा</th>
                  {this.props.isBed && <th>बेड संख्या</th>}
                  {this.props.showPersons && (
                      <th>{this.props.text}मा बस्नेको संख्या</th>
                  )}
                  <th>सम्पर्क</th>
                </thead>
                <tbody>
                {centers.length > 0 ? (
                    centers.map((center, key) => (
                        <tr key={key}
                            className={this.showRow(key) ? 'show' : 'hide'}>
                          <td>{center.name_nep}</td>
                          <td>
                            {center.site_map !== '' ? (
                                <a
                                    className="location__link"
                                    href={center.site_map}
                                    target="_blank "
                                >
                                  {center.address_nep}
                                  <span className="location__icon-wrap">
                              <Svg img="location-icon"/>
                            </span>
                                </a>
                            ) : (
                                center.address_nep
                            )}
                          </td>
                          {this.props.isBed && (
                              <td>{center.total_beds !== '' ?
                                  Helper.numberToNepali(center.total_beds) :
                                  '-'}</td>
                          )}
                          {this.props.showPersons && (
                              <td className="text-center">
                                {(center.no_of_persons !== '' &&
                                    center.no_of_persons !== undefined) ?
                                    Helper.numberToNepali(center.no_of_persons) :
                                    '-'}
                              </td>
                          )}
                          <td>
                            {this.multipleContact(center.contact_number) ? (
                                this.splitContact(center.contact_number)
                            ) : (
                                <a
                                    className="ph__link"
                                    href={'tel:' + center.contact_number}
                                >
                                  {center.contact_number}
                                </a>
                            )}
                          </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                      <td>No Data.</td>
                    </tr>
                )}
                {centers.length > 3 &&
                <tr>
                  <td>
                    {(this.state.showMoreButton) ? (
                        <button
                            className="btn-add-more"
                            onClick={this.showEveryRow}
                        >
                          + थप हेर्नुहोस ...
                        </button>
                    ) : (<button
                        className="btn-add-more"
                        onClick={this.hideEveryRow}
                    >
                      + कम हेर्नुहोस ...
                    </button>)}
                  </td>
                </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
    );
  }
}

export default ImportantCenters;
