
class Helper {

  formatBudget(number){
    let nfObject = new Intl.NumberFormat('en-US');
    return nfObject.format(number);
  }

  formattext(text) {
    return text.toLowerCase().replace(/ /g, '');
  }
  numberToNepali(number) {
    let numbers = {
      '0': '०',
      '०': '०',
      '1': '१',
      '१': '१',
      '2': '२',
      '२': '२',
      '3': '३',
      '३': '३',
      '4': '४',
      '४': '४',
      '5': '५',
      '५': '५',
      '6': '६',
      '६': '६',
      '7': '७',
      '७': '७',
      '8': '८',
      '८': '८',
      '9': '९',
      '९': '९',
      o: '०',
    };
    let numberArray = number.toString().split('');
    let nepaliNumber = numberArray.map(value => {
      return typeof numbers[value] === 'undefined' ? value : numbers[value];
    });
    return nepaliNumber.join('');
  }

  isEmpty(number) {
    if (number === '') {
      return 'N/A';
    }
    if (isNaN(parseInt(number, 10))) {
      return number;
    }
    return this.numberToNepali(number);
  }

  async groupArray(array, key) {
    let group = array.reduce((r, a) => {
      r[a[key]] = [...(r[a[key]] || []), a];
      return r;
    }, {});
    return await group;
  }
  formatNumber(number) {
    let characters = number.toString().split('');
    characters.map((value, index) => {
      if (value === '"' || value === ',' || value === '/' || value === '-') {
        characters.splice(index, 1);
      }
    });
    return characters.join('');
  }

  formatWard(ward) {
    if (!isNaN(parseInt(ward, 10))) {
      return parseInt(ward, 10);
    } else if (ward === 'विविध' || ward === 'सबै') {
      return 9999;
    } else {
      return parseInt(this.numberToEnglish(ward), 10);
    }
  }

  numberToEnglish(number) {
    let numbers = {
      '०': '0',
      '१': '1',
      '२': '2',
      '३': '3',
      '४': '4',
      '५': '5',
      '६': '6',
      '७': '7',
      '८': '8',
      '९': '9',
      ' ': ' ',
      '.': '.',
      '-': '-',
      '/': '/',
      ',': ',',
    };

    const numberArray = number.toString().split('');
    const englishNumber = numberArray.map(value => {
      return numbers[value];
    });
    return englishNumber.join('');
  }

  checkTypeOfNumber(contactNumber) {
    if (!isNaN(parseInt(contactNumber, 10))) {
      return this.numberToNepali(contactNumber);
    } else {
      return contactNumber;
    }
  }


  // conversion string in DD to DMS.
  ddToDms(lat, lng) {
    var lat = lat;
    var lng = lng;
    var latResult, lngResult, dmsResult;

    lat = parseFloat(lat);
    lng = parseFloat(lng);

    latResult = lat >= 0 ? 'N' : 'S';

    // Call to getDms(lat) function for the coordinates of Latitude in DMS.
    // The result is stored in latResult variable.
    latResult += this.getDms(lat);

    lngResult = lng >= 0 ? 'E' : 'W';

    // Call to getDms(lng) function for the coordinates of Longitude in DMS.
    // The result is stored in lngResult variable.
    lngResult += this.getDms(lng);

    // Joining both variables and separate them with a space.
    dmsResult = latResult + ' ' + lngResult;

    // Return the resultant string
    return dmsResult;
  }

  getDms(val) {
    var valDeg, valMin, valSec, result;

    val = Math.abs(val);

    valDeg = Math.floor(val);
    result = valDeg + 'º';

    valMin = Math.floor((val - valDeg) * 60);
    result += valMin + "'";

    valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
    result += valSec + '"';

    return result;
  }
  localGovernment() {
    return [
      'sanfebagar',
      'masta',
      'konjyosom',
      'bagmati',
      'barhabise',
      'sunkoshi',
      'amargadhi',
      'nawadurga',
      'dhangadhi',
      'kailari',
      'bannigadhijayagadh',
      'jayaprithivi',
    ];
  }
  compareValues(key,order='asc'){
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
          (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

}


export default new Helper();
