//Primary
const adhyaaro = '#000E1A';
const baigooni = '#4e00a8';
const raju = '#f0f8ff';
const neelo = '#00529B';

//Secondary

//Neutral
const black = '#000';
const baluwa = '#999FA3';
const jomsom = '#e5e6e8';
const slate = '#59626A';
const white = '#fff';

const Colors = {
  primary: {
    neelo,
    adhyaaro,
    baigooni,
    raju,
  },
  sec: {},
  neutral: {
    black,
    baluwa,
    jomsom,
    slate,
    white,
  },
};

export default Colors;
