import React, {Component} from 'react';
import StatsCard from './statsCard';

class StatsBar extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    let {nationalStats, localStats} = this.props;
    return (
        <>
          <StatsCard nationalStat={nationalStats.tested_total}
                     localStat={localStats.tested_total}
                     title="कूल परिक्षण गरिएकाे (PCR)" colorClass="color-tested"/>
          <StatsCard nationalStat={nationalStats.tested_positive}
                     localStat={localStats.tested_positive}
                     title="संक्रमण देखिएकाे" colorClass="color-infected"/>
          <StatsCard nationalStat={nationalStats.total_death}
                     localStat={localStats.total_death} title="मृत्यु"
                     colorClass="color-death"/>
          <StatsCard nationalStat={nationalStats.total_recovered}
                     localStat={localStats.total_recovered} title="निकाे भएकाे"
                     colorClass="color-recovered"/>
        </>
    );
  }

}

export default StatsBar;
