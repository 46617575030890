import React, { Component } from 'react';
import DateConverter from '../../components/utils/dateConverter';

class Podcast extends Component {
  constructor(props) {
    super(props);
  }

  getVideoId(url) {
    let new_url = new URL(url);
    return new_url.searchParams.get('v');
  }

  getEmbeddedUrl(url) {
    let video_id = this.getVideoId(url);

    return `https://www.youtube.com/embed/${video_id}`;
  }

  convertDate(value) {
    let dateConverter = new DateConverter();
    let date = new Date(value);
    let bsDate = dateConverter.getBsDateByAdDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    return dateConverter.bsDateFormat(
      '%M %d, %y',
      bsDate.bsYear,
      bsDate.bsMonth,
      bsDate.bsDate
    );
  }

  render() {
    let podcasts = this.props.podcasts;
    return (
      <section className="podcast" id="podcasts">
        <div className="heading__title-wrapper">
          <h2 className="heading__secondary">पोडकाष्ट्हरु</h2>
        </div>
        <div className="row">
          {podcasts.map((podcast, key) => (
            <div className="col-1-2 card podcast__card">
              <div className="podcast__wrapper">
                <iframe
                  src={this.getEmbeddedUrl(podcast.podcast_link)}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              {podcast.title_nep !== '' && (
                <h3 className="heading__secondary">{podcast.title_nep}</h3>
              )}
              <span>
                {podcast.date !== '' && this.convertDate(podcast.date)}
              </span>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default Podcast;
