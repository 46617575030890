import React, {Component} from 'react';
import DateConverter from '../../../components/utils/dateConverter';
import Helper from '../../../helper/Helper';
import Svg from "./../svg";


class DonationCard extends Component {
  constructor(props) {
    super(props);
  }

  convertDate(value) {
    let dateConverter = new DateConverter();
    let date = new Date(value);
    let bsDate = dateConverter.getBsDateByAdDate(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
    );
    return dateConverter.bsDateFormat(
        '%M %d, %y',
        bsDate.bsYear,
        bsDate.bsMonth,
        bsDate.bsDate,
    );
  }

  getAmount(donor) {
    if (donor.amount_text !== '') {
      return donor.amount_text;
    }
    return Helper.numberToNepali(Helper.formatBudget(donor.amount));
  }

  render() {
    let donor = this.props.donor;
    let type = this.props.type;
    return (
        <div className={`card donation__card rounded ${this.props.classHide}`}>
          {type === 'cash' && (
              <Svg img="donation-radio"/>
          )}
          {donor.date !== '' && `${this.convertDate(donor.date)} मा `}
          श्री {''}
          <strong> {donor.name_nep} </strong> बाट{' '}
          {type === 'cash' ? (
              <strong> रु {this.getAmount(donor)} </strong>
          ) : (
              <strong> {donor.material} </strong>
          )}
          प्राप्त भयो
        </div>
    );
  }
}

export default DonationCard;
