import React, { Component } from "react";
import Modal from "./covidModal";
import Svg from "./svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Helper from "../../helper/Helper";

class ContactDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.setState({
      show: true,
    });
  }

  closeModal() {
    this.setState({
      show: false,
    });
  }

  multipleContact(contact) {
    return contact.includes(",");
  }

  splitContact(contact) {
    var numbers = contact.split(",");
    return numbers.map((number, key) => {
      return (
        <>
          <a className="link" key={key} href={"tel:" + number}>
            {number}
          </a>
          <br />
        </>
      );
    });
  }

  render() {
    let contacts = this.props.contacts;
    let wardContacts = this.props.wardContacts;
    return (
      <div className="modal__contact-wrapper">
        <>
          <button className="bttn bttn__action" onClick={this.showModal}>
            आपतकालिन सम्पर्क सुची
          </button>
          <Modal
            isOpen={this.state.show}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.closeModal}
            className="modal__contact"
          >
            <div className="modal__content">
              <div className="modal__contact-virus">
                <Svg img="corona-pink" />
              </div>
              <button className="modal__close" onClick={this.closeModal} />
              <Tabs>
                <div
                  className={wardContacts.length < 2 ? `single-tab-item` : ``}
                >
                  <TabList>
                    <Tab>सामान्य जानकारी सुची</Tab>
                    {wardContacts.length > 0 && (
                      <Tab>वडा अध्यक्ष सम्पर्क सुचि</Tab>
                    )}
                  </TabList>
                </div>

                <TabPanel>
                  <h2 className="heading__primary">
                    काेराेना भाइरस (COVID-19) बारे सामान्य जानकारीका लागि
                  </h2>
                  {contacts !== undefined && (
                    <div className="modal__contact-address-listing">
                      {contacts.map((contact, key) => (
                        <div className="modal__contact-location" key={key}>
                          <div className="items-center-vertical relative">
                            <div className="modal__contact-address-virus">
                              <Svg img="corona-small" />
                            </div>
                            <strong>{contact.name_nep}</strong>
                          </div>
                          <span>{contact.address_nep}</span>
                          <strong>
                            {this.splitContact(contact.contact_number)}
                          </strong>
                        </div>
                      ))}
                      {contacts !== undefined && contacts.length < 1 && (
                        <div className="modal__contact-location">
                          <div className="items-center-vertical relative">
                            <strong>कुनै डाटा भेटिएन</strong>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </TabPanel>
                {wardContacts !== undefined && wardContacts.length > 0 && (
                  <TabPanel>
                    <h2 className="heading__primary">
                      वडा प्रतिनिधिहरूको सम्पर्क सुची
                    </h2>
                    <div className="modal__contact-address-listing">
                      {wardContacts.map((contact, key) => (
                        <div className="modal__contact-location" key={key}>
                          <div className="items-center-vertical relative">
                            <div className="modal__contact-address-virus">
                              <Svg img="corona-small" />
                            </div>
                            <strong>{contact.name_nep}</strong>
                          </div>
                          <span>
                            वडा {Helper.numberToNepali(contact.ward_name)}
                          </span>
                          <strong>
                            {this.splitContact(contact.contact_number)}
                          </strong>
                        </div>
                      ))}
                    </div>
                  </TabPanel>
                )}
              </Tabs>
            </div>
          </Modal>
        </>
      </div>
    );
  }
}

export default ContactDetail;
