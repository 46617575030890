import React, {Component} from 'react';
import DonationCard from './donationCard';
import Helper from '../../../helper/Helper';
import Svg from '../svg';

class DonationWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      showMoreButton: true,
    };
    this.showRow = this.showRow.bind(this);
    this.showEveryRow = this.showEveryRow.bind(this);
    this.hideEveryRow = this.hideEveryRow.bind(this);
  }

  showRow(key) {
    if (!this.state.showAll) {
      return key < 5;
    }
    return true;
  }

  showEveryRow() {
    this.setState({
      showAll: true,
      showMoreButton: false,
    });
  }

  hideEveryRow() {
    this.setState({
      showAll: false,
      showMoreButton: true,
    });
  }

  sumAmount = array => array.reduce((a, b) => a + b, 0);

  getSum(donors) {
    let amounts = donors.map(donor => parseFloat(donor.amount)).filter(x => x);
    let sum = this.sumAmount(amounts);
    return `रु ${Helper.numberToNepali(Helper.formatBudget(sum))}`;
  }

  sortDonors() {
    if (this.props.donors === undefined) {
      return undefined;
    }
    let sorted = this.props.donors.sort(Helper.compareValues('date', 'desc'));
    return sorted;
  }

  render() {
    let donors = this.sortDonors();
    let type = this.props.type;
    return (
        <div className="donation__block" id={this.props.id}>
          {donors !== undefined && (
              <span className="donation__text">
            {this.props.title}
                {type === 'cash' && ` (${this.getSum(donors)})`}
          </span>
          )}
          <div class="donation__inner flex">
            {type === 'cash' && (
                <div className="donation__svg">
                  <Svg img="donation"/>
                </div>
            )}

            <div className="donation__cards-list">
              <div>
                {donors.map((donor, key) => (
                    <DonationCard
                        type={this.props.type}
                        key={key}
                        donor={donor}
                        classHide={this.showRow(key) ? 'show' : 'hide'}
                    />
                ))}
              </div>
              {donors.length > 5 && (
                  this.state.showMoreButton ?
                      (
                          <button className="btn-add-more"
                                  onClick={this.showEveryRow}>
                            + थप हेर्नुहोस ...
                          </button>
                      ) :
                      (<button className="btn-add-more"
                               onClick={this.hideEveryRow}>
                        + कम हेर्नुहोस ...
                      </button>))
              }
            </div>
          </div>
        </div>
    );
  }
}

export default DonationWrapper;
