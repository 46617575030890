import $ from "jquery";

class DateConverter {

    constructor() {
        this.calendarData = {
            bsMonths: ["बैशाख", "जेठ", "असार", "सावन", "भदौ", "असोज", "कार्तिक", "मंसिर", "पौष", "माघ", "फागुन", "चैत"],
            bsDays: ["आईत", "सोम", "मंगल", "बुध", "बिही", "शुक्र", "शनि"],
            nepaliNumbers: ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"],
            bsMonthUpperDays: [
                [30, 31],
                [31, 32],
                [31, 32],
                [31, 32],
                [31, 32],
                [30, 31],
                [29, 30],
                [29, 30],
                [29, 30],
                [29, 30],
                [29, 30],
                [30, 31],
            ],
            extractedBsMonthData: [
                [0, 1, 1, 22, 1, 3, 1, 1, 1, 3, 1, 22, 1, 3, 1, 3, 1, 22, 1, 3, 1, 19, 1, 3, 1, 1, 3, 1, 2, 2, 1, 3, 1],
                [1, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 3, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 1, 1, 1, 2, 2, 2, 2, 2, 1, 3, 1, 1, 2],
                [0, 1, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 1, 1, 1, 2, 2, 2, 2, 2, 1, 3, 1, 1, 2],
                [1, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 2, 2, 1, 3, 1, 2, 2, 2, 1, 2],
                [59, 1, 26, 1, 28, 1, 2, 1, 12],
                [0, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 5, 1, 1, 2, 2, 1, 3, 1, 2, 1, 2],
                [0, 12, 1, 3, 1, 3, 1, 5, 1, 11, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 27, 1, 2],
                [1, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 3, 1, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 2, 15, 2, 4],
                [0, 1, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 3, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 15, 2, 4],
                [1, 1, 3, 1, 3, 1, 14, 1, 3, 1, 1, 1, 3, 1, 14, 1, 3, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 3, 1, 14, 1, 3, 15, 1, 2, 1, 1],
                [0, 1, 1, 3, 1, 3, 1, 10, 1, 3, 1, 3, 1, 1, 1, 3, 1, 3, 1, 10, 1, 3, 1, 3, 1, 3, 1, 3, 1, 14, 1, 3, 1, 3, 1, 3, 1, 3, 1, 10, 1, 20, 1, 1, 1],
                [1, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 20, 3],
            ],
            minBsYear: 1970,
            maxBsYear: 2100,
            minAdDateEqBsDate: {
                "ad": {
                    "year": 1913, "month": 3, "date": 13,
                },
                "bs": {
                    "year": 1970, "month": 1, "date": 1,
                },
            },
        };
        this.validationFunctions = {
            validateRequiredParameters: requiredParameters => {
                $.each(requiredParameters, function(key, value) {
                    if (typeof value === "undefined" || value === null) {
                        throw new ReferenceError("Missing required parameters: " + Object.keys(requiredParameters).join(", "));
                    }
                });
            },
            validateBsYear: (bsYear) => {
                if (typeof bsYear !== "number" || bsYear === null) {
                    throw new TypeError("Invalid parameter bsYear value");
                } else if (bsYear < this.calendarData.minBsYear || bsYear > this.calendarData.maxBsYear) {
                    throw new RangeError("Parameter bsYear value should be in range of " + this.calendarData.minBsYear + " to " + this.calendarData.maxBsYear);
                }
            },
            validateAdYear: adYear => {
                if (typeof adYear !== "number" || adYear === null) {
                    throw new TypeError("Invalid parameter adYear value");
                } else if (adYear < this.calendarData.minBsYear - 57 || adYear > this.calendarData.maxBsYear - 57) {
                    throw new RangeError("Parameter adYear value should be in range of " + (this.calendarData.minBsYear - 57) + " to " + (this.calendarData.maxBsYear - 57));
                }
            },
            validateBsMonth: bsMonth => {
                if (typeof bsMonth !== "number" || bsMonth === null) {
                    throw new TypeError("Invalid parameter bsMonth value");
                } else if (bsMonth < 1 || bsMonth > 12) {
                    throw new RangeError("Parameter bsMonth value should be in range of 1 to 12");
                }
            },
            validateAdMonth: adMonth => {
                if (typeof adMonth !== "number" || adMonth === null) {
                    throw new TypeError("Invalid parameter adMonth value");
                } else if (adMonth < 1 || adMonth > 12) {
                    throw new RangeError("Parameter adMonth value should be in range of 1 to 12");
                }
            },
            validateBsDate: bsDate => {
                if (typeof bsDate !== "number" || bsDate === null) {
                    throw new TypeError("Invalid parameter bsDate value");
                } else if (bsDate < 1 || bsDate > 32) {
                    throw new RangeError("Parameter bsDate value should be in range of 1 to 32");
                }
            },
            validateAdDate: adDate => {
                if (typeof adDate !== "number" || adDate === null) {
                    throw new TypeError("Invalid parameter adDate value");
                } else if (adDate < 1 || adDate > 31) {
                    throw new RangeError("Parameter adDate value should be in range of 1 to 31");
                }
            },
            validatePositiveNumber: numberParameters => {
                let _self = this;
                $.each(numberParameters, function(key, value) {
                    if (typeof value !== "number" || value === null || value < 0) {
                        throw new ReferenceError("Invalid parameters: " + Object.keys(numberParameters).join(", "));
                    } else if (key === "yearDiff" && value > (_self.calendarData.maxBsYear - _self.calendarData.minBsYear + 1)) {
                        throw new RangeError("Parameter yearDiff value should be in range of 0 to " + (_self.calendarData.maxBsYear - _self.calendarData.minBsYear + 1));
                    }
                });
            },
        };
    }

    getNepaliNumber(number) {
        if (typeof number === "undefined") {
            throw new Error("Parameter number is required");
        } else if (typeof number !== "number" || number < 0) {
            throw new Error("Number should be positive integer");
        }

        let prefixNum = Math.floor(number / 10);
        let suffixNum = number % 10;
        if (prefixNum !== 0) {
            return this.getNepaliNumber(prefixNum) + this.calendarData.nepaliNumbers[suffixNum];
        } else {
            return this.calendarData.nepaliNumbers[suffixNum];
        }
    }

    /**
     * Return equivalent number from nepaliNumber
     * @param {String} nepaliNumber
     * @returns {Number} number
     */
    getNumberByNepaliNumber(nepaliNumber) {
        if (typeof nepaliNumber === "undefined") {
            throw new Error("Parameter nepaliNumber is required");
        } else if (typeof nepaliNumber !== "string") {
            throw new Error("Parameter nepaliNumber should be in string");
        }

        let number = 0;
        for (let i = 0; i < nepaliNumber.length; i++) {
            let numIndex = this.calendarData.nepaliNumbers.indexOf(nepaliNumber.charAt(i));
            if (numIndex === -1) {
                throw new Error("Invalid nepali number");
            }
            number = number * 10 + numIndex;
        }

        return number;
    }

    getBsMonthInfoByBsDate(bsYear, bsMonth, bsDate, dateFormatPattern) {
        this.validationFunctions.validateRequiredParameters({"bsYear": bsYear, "bsMonth": bsMonth, "bsDate": bsDate});
        this.validationFunctions.validateBsYear(bsYear);
        this.validationFunctions.validateBsMonth(bsMonth);
        this.validationFunctions.validateBsDate(bsDate);
        if (dateFormatPattern === null) {
            dateFormatPattern = "%D, %M %d, %y";
        } else if (typeof dateFormatPattern !== "string") {
            throw new TypeError("Invalid parameter dateFormatPattern value");
        }

        let daysNumFromMinBsYear = this.getTotalDaysNumFromMinBsYear(bsYear, bsMonth, bsDate);
        let adDate = new Date(this.calendarData.minAdDateEqBsDate.ad.year, this.calendarData.minAdDateEqBsDate.ad.month, this.calendarData.minAdDateEqBsDate.ad.date - 1);
        adDate.setDate(adDate.getDate() + daysNumFromMinBsYear);

        let bsMonthFirstAdDate = this.getAdDateByBsDate(bsYear, bsMonth, 1);
        let bsMonthDays = this.getBsMonthDays(bsYear, bsMonth);
        bsDate = (bsDate > bsMonthDays) ? bsMonthDays : bsDate;
        let eqAdDate = this.getAdDateByBsDate(bsYear, bsMonth, bsDate);
        let weekDay = eqAdDate.getDay() + 1;
        let formattedDate = this.bsDateFormat(dateFormatPattern, bsYear, bsMonth, bsDate);
        return {
            bsYear: bsYear,
            bsMonth: bsMonth,
            bsDate: bsDate,
            weekDay: weekDay,
            formattedDate: formattedDate,
            adDate: eqAdDate,
            bsMonthFirstAdDate: bsMonthFirstAdDate,
            bsMonthDays: bsMonthDays,
        };
    }

    getAdDateByBsDate(bsYear, bsMonth, bsDate) {
        this.validationFunctions.validateRequiredParameters({"bsYear": bsYear, "bsMonth": bsMonth, "bsDate": bsDate});
        this.validationFunctions.validateBsYear(bsYear);
        this.validationFunctions.validateBsMonth(bsMonth);
        this.validationFunctions.validateBsDate(bsDate);
        let daysNumFromMinBsYear = this.getTotalDaysNumFromMinBsYear(bsYear, bsMonth, bsDate);
        let adDate = new Date(this.calendarData.minAdDateEqBsDate.ad.year, this.calendarData.minAdDateEqBsDate.ad.month, this.calendarData.minAdDateEqBsDate.ad.date - 1);
        adDate.setDate(adDate.getDate() + daysNumFromMinBsYear);
        return adDate;
    };

    getTotalDaysNumFromMinBsYear(bsYear, bsMonth, bsDate) {
        this.validationFunctions.validateRequiredParameters({"bsYear": bsYear, "bsMonth": bsMonth, "bsDate": bsDate});
        this.validationFunctions.validateBsYear(bsYear);
        this.validationFunctions.validateBsMonth(bsMonth);
        this.validationFunctions.validateBsDate(bsDate);

        if (bsYear < this.calendarData.minBsYear || bsYear > this.calendarData.maxBsYear) {
            return null;
        }

        let daysNumFromMinBsYear = 0;
        let diffYears = bsYear - this.calendarData.minBsYear;
        for (let month = 1; month <= 12; month++) {
            if (month < bsMonth) {
                daysNumFromMinBsYear += this.getMonthDaysNumFormMinBsYear(month, diffYears + 1);
            } else {
                daysNumFromMinBsYear += this.getMonthDaysNumFormMinBsYear(month, diffYears);
            }
        }

        if (bsYear > 2085 && bsYear < 2088) {
            daysNumFromMinBsYear += bsDate - 2;
        } else if (bsYear === 2085 && bsMonth > 5) {
            daysNumFromMinBsYear += bsDate - 2;
        } else if (bsYear > 2088) {
            daysNumFromMinBsYear += bsDate - 4;
        } else if (bsYear === 2088 && bsMonth > 5) {
            daysNumFromMinBsYear += bsDate - 4;
        } else {
            daysNumFromMinBsYear += bsDate;
        }

        return daysNumFromMinBsYear;
    };

    /**
     * Return total number of bsMonth days from minYear
     * @param {int} bsMonth
     * @param {int} yearDiff
     * @returns {int}
     */
    getMonthDaysNumFormMinBsYear(bsMonth, yearDiff) {
        this.validationFunctions.validateRequiredParameters({"bsMonth": bsMonth, "yearDiff": yearDiff});
        this.validationFunctions.validateBsMonth(bsMonth);
        this.validationFunctions.validatePositiveNumber({"yearDiff": yearDiff});

        let yearCount = 0;
        let monthDaysFromMinBsYear = 0;
        if (yearDiff === 0) {
            return 0;
        }

        let bsMonthData = this.calendarData.extractedBsMonthData[bsMonth - 1];
        for (let i = 0; i < bsMonthData.length; i++) {
            if (bsMonthData[i] === 0) {
                continue;
            }

            let bsMonthUpperDaysIndex = i % 2;
            if (yearDiff > yearCount + bsMonthData[i]) {
                yearCount += bsMonthData[i];
                monthDaysFromMinBsYear += this.calendarData.bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] * bsMonthData[i];
            } else {
                monthDaysFromMinBsYear += this.calendarData.bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] * (yearDiff - yearCount);
                yearCount = yearDiff - yearCount;
                break;
            }
        }

        return monthDaysFromMinBsYear;
    }

    /**
     * Return number of bsMonth days
     * @param {int} bsYear
     * @param {int} bsMonth
     * @returns {number|null} days
     */
    getBsMonthDays(bsYear, bsMonth) {
        this.validationFunctions.validateRequiredParameters({"bsYear": bsYear, "bsMonth": bsMonth});
        this.validationFunctions.validateBsYear(bsYear);
        this.validationFunctions.validateBsMonth(bsMonth);

        let yearCount = 0;
        let totalYears = (bsYear + 1) - this.calendarData.minBsYear;
        let bsMonthData = this.calendarData.extractedBsMonthData[bsMonth - 1];
        for (let i = 0; i < bsMonthData.length; i++) {
            if (bsMonthData[i] === 0) {
                continue;
            }

            let bsMonthUpperDaysIndex = i % 2;
            yearCount += bsMonthData[i];
            if (totalYears <= yearCount) {
                if ((bsYear === 2085 && bsMonth === 5) || (bsYear === 2088 && bsMonth === 5)) {
                    return this.calendarData.bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] - 2;
                } else {
                    return this.calendarData.bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex];
                }
            }
        }

        return null;
    }

    getBsDateByAdDate(adYear, adMonth, adDate) {
        this.validationFunctions.validateRequiredParameters({"adYear": adYear, "adMonth": adMonth, "adDate": adDate});
        this.validationFunctions.validateAdYear(adYear);
        this.validationFunctions.validateAdMonth(adMonth);
        this.validationFunctions.validateAdDate(adDate);

        let bsYear = adYear + 57;
        let bsMonth = (adMonth + 9) % 12;
        bsMonth = bsMonth === 0 ? 12 : bsMonth;
        let bsDate = 1;

        if (adMonth < 4) {
            bsYear -= 1;
        } else if (adMonth === 4) {
            let bsYearFirstAdDate = this.getAdDateByBsDate(bsYear, 1, 1);
            if (adDate < bsYearFirstAdDate.getDate()) {
                bsYear -= 1;
            }
        }

        let bsMonthFirstAdDate = this.getAdDateByBsDate(bsYear, bsMonth, 1);
        if (adDate >= 1 && adDate < bsMonthFirstAdDate.getDate()) {
            bsMonth = (bsMonth !== 1) ? bsMonth - 1 : 12;
            let bsMonthDays = this.getBsMonthDays(bsYear, bsMonth);
            bsDate = bsMonthDays - (bsMonthFirstAdDate.getDate() - adDate) + 1;
        } else {
            bsDate = adDate - bsMonthFirstAdDate.getDate() + 1;
        }

        return {
            bsYear: bsYear,
            bsMonth: bsMonth,
            bsDate: bsDate,
        };
    }

    getBsYearByAdDate(adYear, adMonth, adDate) {
        this.validationFunctions.validateRequiredParameters({"adYear": adYear, "adMonth": adMonth, "adDate": adDate});
        this.validationFunctions.validateAdYear(adYear);
        this.validationFunctions.validateAdMonth(adMonth);
        this.validationFunctions.validateAdDate(adDate);

        let bsDate = this.getBsDateByAdDate(adYear, adMonth, adDate);
        return bsDate.bsYear;
    }

    getBsMonthByAdDate(adYear, adMonth, adDate) {
        this.validationFunctions.validateRequiredParameters({"adYear": adYear, "adMonth": adMonth, "adDate": adDate});
        this.validationFunctions.validateAdYear(adYear);
        this.validationFunctions.validateAdMonth(adMonth);
        this.validationFunctions.validateAdDate(adDate);

        let bsDate = this.getBsDateByAdDate(adYear, adMonth, adDate);
        return bsDate.bsMonth;
    }

    bsDateFormat(dateFormatPattern, bsYear, bsMonth, bsDate) {
        this.validationFunctions.validateRequiredParameters({
            "dateFormatPattern": dateFormatPattern,
            "bsYear": bsYear,
            "bsMonth": bsMonth,
            "bsDate": bsDate,
        });
        this.validationFunctions.validateBsYear(bsYear);
        this.validationFunctions.validateBsMonth(bsMonth);
        this.validationFunctions.validateBsDate(bsDate);

        let eqAdDate = this.getAdDateByBsDate(bsYear, bsMonth, bsDate);
        let weekDay = eqAdDate.getDay() + 1;
        let formattedDate = dateFormatPattern;
        formattedDate = formattedDate.replace(/%d/g, this.getNepaliNumber(bsDate));
        formattedDate = formattedDate.replace(/%y/g, this.getNepaliNumber(bsYear));
        formattedDate = formattedDate.replace(/%m/g, this.getNepaliNumber(bsMonth));
        formattedDate = formattedDate.replace(/%M/g, this.calendarData.bsMonths[bsMonth - 1]);
        formattedDate = formattedDate.replace(/%D/g, this.calendarData.bsDays[weekDay - 1]);
        return formattedDate;
    }

    parseFormattedBsDate(dateFormat, dateFormattedText) {
        this.validationFunctions.validateRequiredParameters({
            "dateFormat": dateFormat,
            "dateFormattedText": dateFormattedText,
        });

        let diffTextNum = 0;
        let extractedFormattedBsDate = {
            "bsYear": null,
            "bsMonth": null,
            "bsDate": null,
            "bsDay": null,
        };

        for (let i = 0; i < dateFormat.length; i++) {
            if (dateFormat.charAt(i) === "%") {
                let valueOf = dateFormat.substring(i, i + 2);
                let endChar = dateFormat.charAt(i + 2);
                let tempText = dateFormattedText.substring(i + diffTextNum);
                let endIndex = (endChar !== "") ? tempText.indexOf(endChar) : tempText.length;
                let value = tempText.substring(0, endIndex);

                if (valueOf === "%y") {
                    extractedFormattedBsDate.bsYear = this.getNumberByNepaliNumber(value);
                    diffTextNum += value.length - 2;
                } else if (valueOf === "%d") {
                    extractedFormattedBsDate.bsDate = this.getNumberByNepaliNumber(value);
                    diffTextNum += value.length - 2;
                } else if (valueOf === "%D") {
                    extractedFormattedBsDate.bsDay = this.calendarData.bsDays.indexOf(value) + 1;
                    diffTextNum += value.length - 2;
                } else if (valueOf === "%m") {
                    extractedFormattedBsDate.bsMonth = this.getNumberByNepaliNumber(value);
                    diffTextNum += value.length - 2;
                } else if (valueOf === "%M") {
                    extractedFormattedBsDate.bsMonth = this.calendarData.bsMonths.indexOf(value) + 1;
                    diffTextNum += value.length - 2;
                }
            }
        }

        if (!extractedFormattedBsDate.bsDay) {
            let eqAdDate = this.getAdDateByBsDate(extractedFormattedBsDate.bsYear, extractedFormattedBsDate.bsMonth, extractedFormattedBsDate.bsDate);
            extractedFormattedBsDate.bsDay = eqAdDate.getDay() + 1;
        }

        return extractedFormattedBsDate;
    }
}

export default DateConverter;


