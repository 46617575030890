
exports.melamchi = {
  title: 'मेलम्ची',
  title_eng: 'melamchi',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  district: 'कैलाली',
  covidUrl:'https://docs.google.com/spreadsheets/d/1GOfbSSSYTwZxdLkCwgycXNY7GXde9YZZgIlErvrJlro/edit?usp=sharing',
};
exports.belaka = {
  title: 'वेलका',
  title_eng: 'belaka',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  district: 'उदयपुर',
  covidUrl:'https://docs.google.com/spreadsheets/d/1PZKnYejzZoc4Smh_kdtec8pW3wvvh7_thNP1GydAWs4/edit?usp=sharing',
};
exports.lalbandi = {
  title: 'लालबन्दी',
  title_eng: 'lalbandi',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  district: 'सरलाही',
  covidUrl:'https://docs.google.com/spreadsheets/d/1T_a5sHMcVs_JtQpJ3W64IP7IwSOqNoX0hYQrJTGYQiA/edit?usp=sharing',
};
exports.hetauda = {
  title: 'हेटौडा',
  title_eng: 'hetauda',
  municipality: 'उपमहानगरपालिका',
  council: 'नगरसभा',
  district: 'मकवानपुर',
  covidUrl:'https://docs.google.com/spreadsheets/d/1EzoAsAMG6XuJzbns-xRk2qv0d94u89eJnevCzqzdrWQ/edit?usp=sharing',
};
exports.gorkha = {
  title: 'गोरखा',
  title_eng: 'gorkha',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  district: 'मकवानपुर',
  covidUrl:'https://docs.google.com/spreadsheets/d/13KiVb72IRJXPQz1chpzlBdzsNqoPGhJW7_MBv7tWOSs/edit?usp=sharing',
};
