import React, { Component, Fragment } from "react";
import { media } from "../../../components/styled/utils";
import YouTube from "react-youtube";
import { rgba } from "../../../utils";
import ReactModal from "react-modal";
import Svg from "./../svg";
import styled from "styled-components";


class YoutubeVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      videoDetail: {},
      videoEnd: false,
      image_link: "",
    };
    this.modalPop = this.modalPop.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      videoDetail: nextProps.videoDetail,
      imageLink: nextProps.imageLink,
    });
  }

  modalPop() {
    this.setState({
      show: true,
    });
  }

  closeModal = () => {
    this.setState({ show: false, videoEnd: false });
  };

  onVideoEnd() {
    this.setState({
      videoEnd: true,
    });
  }

  renderVideo = () => {
    const { videoDetail, videoEnd } = this.state;

    const opts = {
      height: "538",
      width: "936",
      playerVars: {
        autoplay: 1,
        rel: 0,
      },
    };

    if (videoDetail.video_id) {
      return (
        <Content>
          <div className="iframe__wrap-parent">
            <Fragment>
              {/*<VideoPlayer onClick={this.closeModal}>*/}
              <YouTube
                ref={(el) => {
                  this.youtube = el;
                }}
                videoId={videoDetail.video_id}
                opts={opts}
                onReady={this._onReady}
                onEnd={this.onVideoEnd}
              />
              {/*</VideoPlayer>*/}
            </Fragment>
          </div>
        </Content>
      );
    }
  };

  render() {
    let imageLink = this.state.imageLink;
    let message = this.props.message;
    return (
      <>
        <a href="#" className="banner__video-btn" onClick={this.modalPop}>
          {imageLink !== "" && (
            <span className="banner__video-avatar">
              <img src={imageLink} />
            </span>
          )}{" "}
          {message !== undefined && (
            <span className="banner__video-text">
              {message.position_nep}को सन्देश
            </span>
          )}
         <Svg img="cheveron-right"/>
        </a>
        <ReactModal
          className="modal__video-player"
          isOpen={this.state.show}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
        >
          <div
            className={` modal__content relative ${
              this.renderVideo() ? `` : `modal__content-desc-only`
            }`}
          >
            <button className="modal__close" onClick={this.closeModal} />
            {this.renderVideo()}
            {message !== undefined && (
              <p className="modal__video-player-desc">{message.message_nep}</p>
            )}
          </div>
        </ReactModal>
      </>
    );
  }
}

export default YoutubeVideo;

const Content = styled.div`
  padding-top: 48px;
  ${media.tablet`
      display: flex;
      height: 100%;
      padding-top: 0;
    `};

  .go-top {
    display: none;
    position: fixed;
    right: 30px;
    bottom: 20px;
  }
  .social-wrapper {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: calc(100% - 385px);
    height: 48px;
    .share-dropdown--mobile {
      margin-right: 20px;
      float: right;
      width: 48px;
      height: 48px;
      cursor: pointer;
      z-index: 12;
      display: flex;
      justify-content: center;
      align-items: center;
      .show {
        margin-top: 44px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding-top: 0;
    height: 100vh;
    width: 100%;
    .go-top.show {
      display: inline-block;
      z-index: 11;
    }
    .social-wrapper {
      width: 100%;
      position: relative;
      > div:last-child {
        position: absolute;
      }
      .share-dropdown--mobile {
        .show {
          left: -90px;
          transform: none;
          &:before {
            left: 90%;
          }
        }
      }
    }
  }
`;
const VideoPlayer = styled.div`
  background-color: rgba(0, 14, 26, 0.8);
  ${media.tablet`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
    `};

  ${media.desktop`
        padding: 35px;
    `};

  > span {
    padding-bottom: 56.2%;
    height: 0;
    overflow: hiden;
    overflow: hidden;
    display: flex;
    position: relative;
    flex: 1 1 0%;
    align-self: center;

    > iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
`;
const BackButton = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  .caret {
    display: inline-block;
    &:after {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-top: 2px solid ${rgba("#fff", 0.5)};
      border-right: 2px solid ${rgba("#fff", 0.5)};
      transform: rotate(-135deg);
    }
  }

  > span {
    font-size: 1rem;
    color: ${rgba("#fff", 0.5)};
    transition: color 0.1s ease-in;

    &:hover {
      color: #fff;
    }
  }

  @media only screen and (max-width: 767px) {
    position: inherit;
    background: #008acb;
    width: 100%;
    top: 0;
    left: 0;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    .caret:after {
      width: 9px;
      height: 9px;
    }
    > span {
      opacity: 1;
      color: #f0f8ff;
      margin-left: 4px;
    }
  }
`;
