import React, {Component} from 'react';
import Helper from '../../../helper/Helper';

class StatsCard extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    let {nationalStat, localStat, colorClass, title} = this.props;
    return (
        <div className="card banner__stats-card col-1-4">
                    <span className="color-dark-light">
                      {title}
                    </span>
          <span
              className={`banner__stats-mun ${colorClass}`}>{Helper.numberToNepali(localStat)}</span>
          <span
              className="banner__stats-country  color-dark-lightest">
                      राष्ट्रिय स्तर:
                      <span
                          className={`${colorClass} text-small-bold`}>{Helper.numberToNepali(nationalStat)}</span>
                    </span>
        </div>
    );
  }

}

export default StatsCard;
