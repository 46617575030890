
const img = path => `/img/${path}`;
const icons = path => img(`icons/${path}`);

export default {
  susasanLogo: img('susasan-logo.svg'),
  susasanLogoBlue: img('susasan-logo-blue.svg'),
  logoBlue: img('susasan-logo-blue.svg'),
  playStore: img('google-play-badge.svg'),
  munApp: img('mun-app.jpg'),
  governmentLogo: img('government-logo.png'),
  icons: {
    link: icons('link.svg'),
    forward: icons('forward.svg'),
    icSprites: icons('ic-sprites.svg'),
    dataSprites: icons('data-sprites.svg'),
    leaf: icons('leaf.svg'),
    noData: icons('no-data.svg'),
  },
};
