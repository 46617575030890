import React from 'react';

function Svg(props) {
    if (props.img === 'corona-large') {
        return (
            <svg viewBox="0 0 390 341" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M220 290C300.081 290 365 225.081 365 145C365 64.9187 300.081 0 220 0C139.919 0 75 64.9187 75 145C75 225.081 139.919 290 220 290Z"
                    fill="#1063A7"
                />
                <path
                    d="M177.651 340.571C265.421 340.571 336.573 269.503 336.573 181.836C336.573 94.1691 265.421 23.101 177.651 23.101C89.8806 23.101 18.7288 94.1691 18.7288 181.836C18.7288 269.503 89.8806 340.571 177.651 340.571Z"
                    fill="#DBF4FF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M169.162 22.9415C196.955 25.3405 223.671 34.0875 245.806 51.0671C267.517 67.7206 285.652 90.0843 291.535 116.81C297.132 142.231 285.5 167.028 276.73 191.535C268.246 215.242 262.263 241.915 241.612 256.315C221.103 270.616 194.089 264.619 169.162 266.543C141.541 268.676 113.633 278.922 88.1023 268.167C59.1518 255.971 28.0702 235.254 21.802 204.467C15.5655 173.835 47.418 149.866 58.0302 120.463C68.1173 92.5148 59.9313 56.5466 82.2626 36.9502C104.871 17.1109 139.197 20.3549 169.162 22.9415Z"
                    fill="#AEE6FF"
                />
                <path
                    d="M157.5 235.5C155.333 232.333 152.3 226.4 157.5 228C164 230 172 236.5 168.5 241.5C165 246.5 165.5 252 161.5 251"
                    stroke="#DDF4FF"
                />
                <path
                    d="M112.5 243.5C109.333 240.833 102.1 236.5 98.5 240.5C94 245.5 95.5 253 97 257C98.5 261 107.5 272 114.5 259.5"
                    stroke="#DDF4FF"
                />
                <path
                    d="M158.5 234C130.9 227.6 114.333 236.667 109.5 242C110.333 245.5 112.4 253.9 114 259.5C141.6 267.5 157.167 256.167 161.5 249.5C161 247 159.7 240.4 158.5 234Z"
                    fill="#DDF5FF"
                />
                <path
                    d="M124.5 242C127.333 239.833 135.3 235.9 144.5 237.5"
                    stroke="#C6E5F4"
                />
                <path
                    d="M130.112 258.616C133.234 258.867 141.543 258.329 149.807 254.169"
                    stroke="#C6E5F4"
                />
                <path
                    d="M264.472 234.044C312.392 234.044 351.238 195.243 351.238 147.38C351.238 99.5166 312.392 60.7159 264.472 60.7159C216.553 60.7159 177.706 99.5166 177.706 147.38C177.706 195.243 216.553 234.044 264.472 234.044Z"
                    fill="#29ABE2"
                />
                <path
                    d="M268.05 35.1038H260.895V69.0546H268.05V35.1038Z"
                    fill="#29ABE2"
                />
                <path
                    d="M264.473 48.2076C271.718 48.2076 277.592 42.3409 277.592 35.1038C277.592 27.8668 271.718 22 264.473 22C257.227 22 251.353 27.8668 251.353 35.1038C251.353 42.3409 257.227 48.2076 264.473 48.2076Z"
                    fill="#29ABE2"
                />
                <path
                    d="M211.351 48.3399L205.154 51.9136L222.149 81.3151L228.346 77.7413L211.351 48.3399Z"
                    fill="#29ABE2"
                />
                <path
                    d="M212.413 67.8634C218.242 67.8634 222.968 63.1433 222.968 57.3208C222.968 51.4982 218.242 46.7781 212.413 46.7781C206.583 46.7781 201.858 51.4982 201.858 57.3208C201.858 63.1433 206.583 67.8634 212.413 67.8634Z"
                    fill="#29ABE2"
                />
                <path
                    d="M168.901 88.17L165.323 94.3598L194.759 111.335L198.337 105.145L168.901 88.17Z"
                    fill="#29ABE2"
                />
                <path
                    d="M167.151 104.316C174.397 104.316 180.271 98.449 180.271 91.212C180.271 83.9749 174.397 78.1082 167.151 78.1082C159.906 78.1082 154.032 83.9749 154.032 91.212C154.032 98.449 159.906 104.316 167.151 104.316Z"
                    fill="#29ABE2"
                />
                <path
                    d="M152.064 143.806L152.064 150.954L186.055 150.954L186.055 143.806L152.064 143.806Z"
                    fill="#29ABE2"
                />
                <path
                    d="M152.064 160.484C159.31 160.484 165.183 154.617 165.183 147.38C165.183 140.143 159.31 134.276 152.064 134.276C144.819 134.276 138.945 140.143 138.945 147.38C138.945 154.617 144.819 160.484 152.064 160.484Z"
                    fill="#29ABE2"
                />
                <path
                    d="M165.342 200.446L168.92 206.636L198.356 189.66L194.778 183.471L165.342 200.446Z"
                    fill="#29ABE2"
                />
                <path
                    d="M167.151 216.651C174.397 216.651 180.271 210.785 180.271 203.548C180.271 196.311 174.397 190.444 167.151 190.444C159.906 190.444 154.032 196.311 154.032 203.548C154.032 210.785 159.906 216.651 167.151 216.651Z"
                    fill="#29ABE2"
                />
                <path
                    d="M205.186 242.813L211.383 246.387L228.378 216.985L222.181 213.412L205.186 242.813Z"
                    fill="#29ABE2"
                />
                <path
                    d="M211.22 248.22C216.193 248.22 220.225 244.193 220.225 239.226C220.225 234.258 216.193 230.232 211.22 230.232C206.247 230.232 202.216 234.258 202.216 239.226C202.216 244.193 206.247 248.22 211.22 248.22Z"
                    fill="#29ABE2"
                />
                <path
                    d="M260.895 259.656L268.051 259.656L268.051 225.705L260.895 225.705L260.895 259.656Z"
                    fill="#29ABE2"
                />
                <path
                    d="M264.473 272.76C271.718 272.76 277.592 266.893 277.592 259.656C277.592 252.419 271.718 246.552 264.473 246.552C257.227 246.552 251.353 252.419 251.353 259.656C251.353 266.893 257.227 272.76 264.473 272.76Z"
                    fill="#29ABE2"
                />
                <path
                    d="M317.575 246.387L323.772 242.813L306.777 213.412L300.58 216.985L317.575 246.387Z"
                    fill="#29ABE2"
                />
                <path
                    d="M320.706 257.69C327.952 257.69 333.826 251.823 333.826 244.586C333.826 237.349 327.952 231.483 320.706 231.483C313.461 231.483 307.587 237.349 307.587 244.586C307.587 251.823 313.461 257.69 320.706 257.69Z"
                    fill="#29ABE2"
                />
                <path
                    d="M353.465 203.644L357.043 197.455L327.607 180.479L324.029 186.669L353.465 203.644Z"
                    fill="#29ABE2"
                />
                <path
                    d="M355.234 209.623C360.24 209.623 364.298 205.57 364.298 200.569C364.298 195.569 360.24 191.516 355.234 191.516C350.228 191.516 346.17 195.569 346.17 200.569C346.17 205.57 350.228 209.623 355.234 209.623Z"
                    fill="#29ABE2"
                />
                <path
                    d="M376.881 150.954L376.881 143.806L342.89 143.806L342.89 150.954L376.881 150.954Z"
                    fill="#29ABE2"
                />
                <path
                    d="M376.881 160.484C384.126 160.484 390 154.617 390 147.38C390 140.143 384.126 134.276 376.881 134.276C369.635 134.276 363.761 140.143 363.761 147.38C363.761 154.617 369.635 160.484 376.881 160.484Z"
                    fill="#29ABE2"
                />
                <path
                    d="M363.622 94.3464L360.044 88.1566L330.608 105.132L334.186 111.322L363.622 94.3464Z"
                    fill="#29ABE2"
                />
                <path
                    d="M354.638 105.864C360.434 105.864 365.133 101.171 365.133 95.3814C365.133 89.5918 360.434 84.8984 354.638 84.8984C348.841 84.8984 344.142 89.5918 344.142 95.3814C344.142 101.171 348.841 105.864 354.638 105.864Z"
                    fill="#29ABE2"
                />
                <path
                    d="M323.778 51.9136L317.581 48.3399L300.585 77.7413L306.782 81.315L323.778 51.9136Z"
                    fill="#29ABE2"
                />
                <path
                    d="M320.706 63.277C327.952 63.277 333.826 57.4103 333.826 50.1732C333.826 42.9362 327.952 37.0694 320.706 37.0694C313.461 37.0694 307.587 42.9362 307.587 50.1732C307.587 57.4103 313.461 63.277 320.706 63.277Z"
                    fill="#29ABE2"
                />
                <path
                    d="M315.936 127.426C320.711 127.426 324.583 123.56 324.583 118.79C324.583 114.02 320.711 110.153 315.936 110.153C311.16 110.153 307.289 114.02 307.289 118.79C307.289 123.56 311.16 127.426 315.936 127.426Z"
                    fill="#1B6F94"
                />
                <path
                    d="M332.037 144.699C336.812 144.699 340.683 140.833 340.683 136.063C340.683 131.293 336.812 127.426 332.037 127.426C327.261 127.426 323.39 131.293 323.39 136.063C323.39 140.833 327.261 144.699 332.037 144.699Z"
                    fill="#1B6F94"
                />
                <path
                    d="M312.954 147.082C316.412 147.082 319.216 144.282 319.216 140.828C319.216 137.374 316.412 134.574 312.954 134.574C309.496 134.574 306.693 137.374 306.693 140.828C306.693 144.282 309.496 147.082 312.954 147.082Z"
                    fill="#1B6F94"
                />
                <path
                    d="M56.1744 244.944C77.6147 244.944 94.9955 227.583 94.9955 206.168C94.9955 184.753 77.6147 167.393 56.1744 167.393C34.7341 167.393 17.3533 184.753 17.3533 206.168C17.3533 227.583 34.7341 244.944 56.1744 244.944Z"
                    fill="#F970A1"
                />
                <path
                    d="M57.7844 155.897H54.5642V171.086H57.7844V155.897Z"
                    fill="#F970A1"
                />
                <path
                    d="M56.1744 161.734C59.4019 161.734 62.0184 159.121 62.0184 155.897C62.0184 152.674 59.4019 150.06 56.1744 150.06C52.9468 150.06 50.3303 152.674 50.3303 155.897C50.3303 159.121 52.9468 161.734 56.1744 161.734Z"
                    fill="#F970A1"
                />
                <path
                    d="M32.4068 161.821L29.6182 163.429L37.2213 176.583L40.01 174.975L32.4068 161.821Z"
                    fill="#F970A1"
                />
                <path
                    d="M32.8577 170.55C35.4596 170.55 37.5688 168.443 37.5688 165.844C37.5688 163.245 35.4596 161.139 32.8577 161.139C30.2559 161.139 28.1467 163.245 28.1467 165.844C28.1467 168.443 30.2559 170.55 32.8577 170.55Z"
                    fill="#F970A1"
                />
                <path
                    d="M13.3976 179.624L11.7875 182.41L24.9562 190.004L26.5663 187.218L13.3976 179.624Z"
                    fill="#F970A1"
                />
                <path
                    d="M12.5826 186.87C15.8101 186.87 18.4266 184.257 18.4266 181.033C18.4266 177.809 15.8101 175.196 12.5826 175.196C9.35499 175.196 6.73853 177.809 6.73853 181.033C6.73853 184.257 9.35499 186.87 12.5826 186.87Z"
                    fill="#F970A1"
                />
                <path
                    d="M5.85864 204.535L5.85864 207.751L21.0651 207.751L21.0651 204.535L5.85864 204.535Z"
                    fill="#F970A1"
                />
                <path
                    d="M5.84404 212.005C9.07161 212.005 11.6881 209.392 11.6881 206.168C11.6881 202.945 9.07161 200.331 5.84404 200.331C2.61646 200.331 0 202.945 0 206.168C0 209.392 2.61646 212.005 5.84404 212.005Z"
                    fill="#F970A1"
                />
                <path
                    d="M11.7872 229.861L13.3973 232.646L26.5661 225.052L24.956 222.267L11.7872 229.861Z"
                    fill="#F970A1"
                />
                <path
                    d="M12.5826 237.081C15.8101 237.081 18.4266 234.468 18.4266 231.244C18.4266 228.021 15.8101 225.407 12.5826 225.407C9.35499 225.407 6.73853 228.021 6.73853 231.244C6.73853 234.468 9.35499 237.081 12.5826 237.081Z"
                    fill="#F970A1"
                />
                <path
                    d="M29.6062 248.843L32.3949 250.451L39.9981 237.298L37.2094 235.689L29.6062 248.843Z"
                    fill="#F970A1"
                />
                <path
                    d="M32.3211 251.257C34.5277 251.257 36.3165 249.471 36.3165 247.267C36.3165 245.063 34.5277 243.276 32.3211 243.276C30.1145 243.276 28.3257 245.063 28.3257 247.267C28.3257 249.471 30.1145 251.257 32.3211 251.257Z"
                    fill="#F970A1"
                />
                <path
                    d="M54.5245 256.371L57.7446 256.371L57.7446 241.182L54.5245 241.182L54.5245 256.371Z"
                    fill="#F970A1"
                />
                <path
                    d="M56.1744 262.217C59.4019 262.217 62.0184 259.604 62.0184 256.38C62.0184 253.156 59.4019 250.543 56.1744 250.543C52.9468 250.543 50.3303 253.156 50.3303 256.38C50.3303 259.604 52.9468 262.217 56.1744 262.217Z"
                    fill="#F970A1"
                />
                <path
                    d="M79.8823 250.471L82.671 248.862L75.0678 235.709L72.2792 237.317L79.8823 250.471Z"
                    fill="#F970A1"
                />
                <path
                    d="M81.2798 255.486C84.5074 255.486 87.1239 252.873 87.1239 249.649C87.1239 246.425 84.5074 243.812 81.2798 243.812C78.0523 243.812 75.4358 246.425 75.4358 249.649C75.4358 252.873 78.0523 255.486 81.2798 255.486Z"
                    fill="#F970A1"
                />
                <path
                    d="M95.9617 231.294L97.5718 228.508L84.403 220.914L82.7929 223.699L95.9617 231.294Z"
                    fill="#F970A1"
                />
                <path
                    d="M96.7844 233.984C99.0239 233.984 100.839 232.171 100.839 229.934C100.839 227.697 99.0239 225.884 96.7844 225.884C94.5449 225.884 92.7294 227.697 92.7294 229.934C92.7294 232.171 94.5449 233.984 96.7844 233.984Z"
                    fill="#F970A1"
                />
                <path
                    d="M106.45 207.791L106.45 204.575L91.2439 204.575L91.2439 207.791L106.45 207.791Z"
                    fill="#F970A1"
                />
                <path
                    d="M106.445 212.005C109.673 212.005 112.289 209.392 112.289 206.168C112.289 202.945 109.673 200.331 106.445 200.331C103.217 200.331 100.601 202.945 100.601 206.168C100.601 209.392 103.217 212.005 106.445 212.005Z"
                    fill="#F970A1"
                />
                <path
                    d="M100.541 182.441L98.9312 179.656L85.7624 187.25L87.3725 190.035L100.541 182.441Z"
                    fill="#F970A1"
                />
                <path
                    d="M96.4862 187.585C99.088 187.585 101.197 185.478 101.197 182.879C101.197 180.28 99.088 178.174 96.4862 178.174C93.8843 178.174 91.7751 180.28 91.7751 182.879C91.7751 185.478 93.8843 187.585 96.4862 187.585Z"
                    fill="#F970A1"
                />
                <path
                    d="M82.7224 163.449L79.9337 161.841L72.3305 174.994L75.1192 176.603L82.7224 163.449Z"
                    fill="#F970A1"
                />
                <path
                    d="M81.2798 168.465C84.5074 168.465 87.1239 165.852 87.1239 162.628C87.1239 159.404 84.5074 156.791 81.2798 156.791C78.0523 156.791 75.4358 159.404 75.4358 162.628C75.4358 165.852 78.0523 168.465 81.2798 168.465Z"
                    fill="#F970A1"
                />
                <path
                    d="M79.1927 197.234C81.3334 197.234 83.0688 195.501 83.0688 193.362C83.0688 191.224 81.3334 189.491 79.1927 189.491C77.0519 189.491 75.3165 191.224 75.3165 193.362C75.3165 195.501 77.0519 197.234 79.1927 197.234Z"
                    fill="#AB4C6F"
                />
                <path
                    d="M86.4082 204.977C88.549 204.977 90.2844 203.244 90.2844 201.105C90.2844 198.967 88.549 197.234 86.4082 197.234C84.2675 197.234 82.5321 198.967 82.5321 201.105C82.5321 203.244 84.2675 204.977 86.4082 204.977Z"
                    fill="#AB4C6F"
                />
                <path
                    d="M77.8808 205.99C79.4287 205.99 80.6835 204.736 80.6835 203.19C80.6835 201.644 79.4287 200.391 77.8808 200.391C76.3328 200.391 75.078 201.644 75.078 203.19C75.078 204.736 76.3328 205.99 77.8808 205.99Z"
                    fill="#AB4C6F"
                />
            </svg>
        );
    }
    else if (props.img === 'corona-green') {
        return (
            <svg
                width="131"
                height="132"
                viewBox="0 0 131 132"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M49.5178 80.9107C57.8111 89.9617 71.8628 90.5838 80.9031 82.3002C89.9434 74.0167 90.5489 59.9643 82.2556 50.9133C73.9623 41.8624 59.9106 41.2403 50.8703 49.5238C41.8301 57.8074 41.2245 71.8598 49.5178 80.9107Z"
                    fill="#46C0B8"
                />
                <path
                    d="M87.7316 47.1731L86.6069 45.7871L80.0742 51.6354L81.1989 53.0215L87.7316 47.1731Z"
                    fill="#46C0B8"
                />
                <path
                    d="M84.5076 48.7046C85.6806 50.0782 87.7959 50.1897 89.2323 48.9537C90.6687 47.7178 90.8822 45.6023 89.7093 44.2288C88.5363 42.8553 86.421 42.7437 84.9846 43.9797C83.5482 45.2157 83.3346 47.3311 84.5076 48.7046Z"
                    fill="#46C0B8"
                />
                <path
                    d="M75.4141 38.7389L73.8496 38.1697L71.0449 46.4531L72.6094 47.0223L75.4141 38.7389Z"
                    fill="#46C0B8"
                />
                <path
                    d="M71.7938 42.2753C72.7394 43.3826 74.4445 43.4725 75.6025 42.4761C76.7604 41.4798 76.9325 39.7745 75.987 38.6673C75.0414 37.5601 73.3362 37.4702 72.1783 38.4665C71.0204 39.4628 70.8483 41.1681 71.7938 42.2753Z"
                    fill="#46C0B8"
                />
                <path
                    d="M60.829 37.5864L58.7476 37.9687L60.3526 46.4808L62.4341 46.0986L60.829 37.5864Z"
                    fill="#46C0B8"
                />
                <path
                    d="M57.0354 40.0251C58.2084 41.3987 60.3237 41.5102 61.7601 40.2742C63.1965 39.0383 63.4101 36.9229 62.2371 35.5493C61.0642 34.1758 58.9488 34.0643 57.5124 35.3002C56.076 36.5362 55.8625 38.6516 57.0354 40.0251Z"
                    fill="#46C0B8"
                />
                <path
                    d="M47.2425 44.0396L45.7563 45.2732L51.51 51.7031L52.9962 50.4696L47.2425 44.0396Z"
                    fill="#46C0B8"
                />
                <path
                    d="M43.8182 46.9032C44.9911 48.2767 47.1064 48.3882 48.5428 47.1523C49.9792 45.9163 50.1928 43.8009 49.0198 42.4274C47.8469 41.0539 45.7316 40.9423 44.2952 42.1783C42.8588 43.4143 42.6452 45.5297 43.8182 46.9032Z"
                    fill="#46C0B8"
                />
                <path
                    d="M38.8123 56.3387L38.1626 58.1005L46.3656 60.7174L47.0153 58.9557L38.8123 56.3387Z"
                    fill="#46C0B8"
                />
                <path
                    d="M35.8382 59.4474C37.0111 60.821 39.1265 60.9325 40.5629 59.6965C41.9993 58.4606 42.2128 56.3452 41.0399 54.9716C39.8669 53.5981 37.7516 53.4866 36.3152 54.7226C34.8788 55.9585 34.6652 58.0739 35.8382 59.4474Z"
                    fill="#46C0B8"
                />
                <path
                    d="M37.5955 71.1058L37.877 73.0025L46.4889 71.2218L46.2075 69.3251L37.5955 71.1058Z"
                    fill="#46C0B8"
                />
                <path
                    d="M37.5325 73.2697C38.3344 74.2087 39.7806 74.285 40.7626 73.44C41.7446 72.595 41.8906 71.1487 41.0887 70.2097C40.2868 69.2707 38.8406 69.1944 37.8586 70.0394C36.8766 70.8844 36.7306 72.3306 37.5325 73.2697Z"
                    fill="#46C0B8"
                />
                <path
                    d="M44.0555 84.6076L45.1802 85.9937L51.7129 80.1453L50.5883 78.7592L44.0555 84.6076Z"
                    fill="#46C0B8"
                />
                <path
                    d="M42.0892 87.5722C43.2621 88.9457 45.3774 89.0572 46.8138 87.8213C48.2502 86.5853 48.4638 84.4699 47.2908 83.0964C46.1179 81.7229 44.0026 81.6113 42.5662 82.8473C41.1298 84.0833 40.9162 86.1987 42.0892 87.5722Z"
                    fill="#46C0B8"
                />
                <path
                    d="M56.3555 93.0428L57.9199 93.612L60.7247 85.3286L59.1602 84.7594L56.3555 93.0428Z"
                    fill="#46C0B8"
                />
                <path
                    d="M54.6419 95.5669C55.8149 96.9404 57.9302 97.052 59.3666 95.816C60.803 94.58 61.0165 92.4646 59.8436 91.0911C58.6706 89.7176 56.5553 89.6061 55.1189 90.842C53.6825 92.078 53.4689 94.1934 54.6419 95.5669Z"
                    fill="#46C0B8"
                />
                <path
                    d="M70.3877 92.4269L72.4692 92.0447L70.8641 83.5325L68.7826 83.9147L70.3877 92.4269Z"
                    fill="#46C0B8"
                />
                <path
                    d="M69.7584 93.801C70.5723 94.7541 72.04 94.8315 73.0367 93.9739C74.0334 93.1162 74.1816 91.6484 73.3677 90.6953C72.5538 89.7423 71.0861 89.6649 70.0894 90.5225C69.0927 91.3801 68.9445 92.8479 69.7584 93.801Z"
                    fill="#46C0B8"
                />
                <path
                    d="M84.5207 87.7635L86.0068 86.53L80.2533 80.1L78.7671 81.3336L84.5207 87.7635Z"
                    fill="#46C0B8"
                />
                <path
                    d="M82.7312 89.3715C83.9042 90.7451 86.0195 90.8566 87.4559 89.6206C88.8923 88.3847 89.1059 86.2692 87.9329 84.8957C86.76 83.5222 84.6446 83.4106 83.2082 84.6466C81.7718 85.8826 81.5583 87.998 82.7312 89.3715Z"
                    fill="#46C0B8"
                />
                <path
                    d="M92.968 75.4635L93.6177 73.7017L85.4146 71.0847L84.7649 72.8464L92.968 75.4635Z"
                    fill="#46C0B8"
                />
                <path
                    d="M89.2147 75.7252C90.1602 76.8325 91.8654 76.9224 93.0234 75.926C94.1813 74.9297 94.3535 73.2244 93.4079 72.1171C92.4623 71.0099 90.7571 70.92 89.5992 71.9163C88.4413 72.9127 88.2691 74.618 89.2147 75.7252Z"
                    fill="#46C0B8"
                />
                <path
                    d="M94.1896 60.6925L93.9082 58.7958L85.2963 60.5766L85.5777 62.4732L94.1896 60.6925Z"
                    fill="#46C0B8"
                />
                <path
                    d="M91.3777 61.9063C92.5507 63.2798 94.666 63.3914 96.1024 62.1554C97.5388 60.9194 97.7524 58.804 96.5794 57.4305C95.4064 56.057 93.2911 55.9454 91.8547 57.1814C90.4183 58.4174 90.2048 60.5328 91.3777 61.9063Z"
                    fill="#46C0B8"
                />
                <path
                    d="M78.4713 72.16C79.2493 73.071 80.6523 73.145 81.605 72.3252C82.5577 71.5054 82.6993 70.1024 81.9213 69.1913C81.1434 68.2803 79.7404 68.2064 78.7877 69.0261C77.835 69.8459 77.6933 71.249 78.4713 72.16Z"
                    fill="#3FA69F"
                />
                <path
                    d="M77.9937 78.2011C78.7717 79.1121 80.1747 79.1861 81.1275 78.3663C82.0802 77.5465 82.2218 76.1435 81.4438 75.2325C80.6658 74.3214 79.2628 74.2475 78.3101 75.0672C77.3574 75.887 77.2157 77.2901 77.9937 78.2011Z"
                    fill="#3FA69F"
                />
                <path
                    d="M74.2929 74.9966C74.8555 75.6553 75.87 75.7088 76.5588 75.1161C77.2477 74.5233 77.3501 73.5088 76.7876 72.85C76.2251 72.1913 75.2106 72.1378 74.5217 72.7306C73.8328 73.3233 73.7304 74.3379 74.2929 74.9966Z"
                    fill="#3FA69F"
                />
            </svg>
        );
    }
    else if (props.img === 'donation') {
        return (
            <svg
                width="188"
                height="216"
                viewBox="0 0 188 216"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M84.5 169C131.168 169 169 131.168 169 84.5C169 37.8319 131.168 0 84.5 0C37.8319 0 0 37.8319 0 84.5C0 131.168 37.8319 169 84.5 169Z"
                    fill="#DBF4FF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M79.4386 1.02466C94.1525 2.29081 108.296 6.9073 120.015 15.8687C131.509 24.6581 141.11 36.4612 144.225 50.5662C147.187 63.9828 141.029 77.0703 136.386 90.0048C131.895 102.517 128.728 116.594 117.795 124.194C106.937 131.742 92.6353 128.577 79.4386 129.592C64.8157 130.718 50.0412 136.126 36.5248 130.449C21.198 124.013 4.74306 113.079 1.42458 96.8298C-1.8771 80.6631 14.986 68.0126 20.6042 52.4943C25.9445 37.7439 21.6107 18.7607 33.4331 8.41817C45.4021 -2.05258 63.5748 -0.340452 79.4386 1.02466Z"
                    fill="#AEE6FF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M61.3443 116.502C61.4494 117.044 61.9741 117.398 62.5163 117.293L137.78 102.706C138.322 102.601 138.676 102.076 138.571 101.534L131.142 63.205C131.037 62.6628 130.512 62.3085 129.97 62.4136L54.7068 77.0011C54.1646 77.1062 53.8103 77.6309 53.9154 78.1731L61.3443 116.502ZM106.316 87.9011C107.395 93.4643 103.759 98.8483 98.1956 99.9265C92.6324 101.005 87.2485 97.369 86.1702 91.8058C85.0919 86.2426 88.7277 80.8587 94.2909 79.7804C99.8541 78.7022 105.238 82.3379 106.316 87.9011ZM102.959 88.552C103.677 92.2608 101.254 95.8501 97.5448 96.5689C93.836 97.2878 90.2467 94.8639 89.5278 91.1551C88.809 87.4463 91.2328 83.857 94.9416 83.1382C98.6504 82.4194 102.24 84.8432 102.959 88.552Z"
                    fill="#46C0B8"
                />
                <path
                    opacity="0.7"
                    d="M52.4232 70.4761L128.999 52.1503L130.301 58.8657L53.074 73.8338L52.4232 70.4761Z"
                    fill="#46C0B8"
                />
                <path
                    opacity="0.5"
                    d="M51.1219 63.7608L127.047 42.0773L128.349 48.7927L51.7727 67.1185L51.1219 63.7608Z"
                    fill="#46C0B8"
                />
                <path
                    d="M22.9524 122.664C31.5616 122.664 38.5406 115.693 38.5406 107.094C38.5406 98.495 31.5616 91.5242 22.9524 91.5242C14.3433 91.5242 7.36426 98.495 7.36426 107.094C7.36426 115.693 14.3433 122.664 22.9524 122.664Z"
                    fill="#F970A1"
                />
                <path
                    d="M23.5992 86.9082H22.3062V93.007H23.5992V86.9082Z"
                    fill="#F970A1"
                />
                <path
                    d="M22.9526 89.2521C24.2486 89.2521 25.2992 88.2028 25.2992 86.9083C25.2992 85.6138 24.2486 84.5645 22.9526 84.5645C21.6566 84.5645 20.606 85.6138 20.606 86.9083C20.606 88.2028 21.6566 89.2521 22.9526 89.2521Z"
                    fill="#F970A1"
                />
                <path
                    d="M13.4093 89.2871L12.2896 89.9329L15.3426 95.2144L16.4624 94.5686L13.4093 89.2871Z"
                    fill="#F970A1"
                />
                <path
                    d="M13.5904 92.7918C14.6351 92.7918 15.482 91.9459 15.482 90.9024C15.482 89.8589 14.6351 89.0129 13.5904 89.0129C12.5457 89.0129 11.6987 89.8589 11.6987 90.9024C11.6987 91.9459 12.5457 92.7918 13.5904 92.7918Z"
                    fill="#F970A1"
                />
                <path
                    d="M5.77638 96.4355L5.12988 97.554L10.4176 100.603L11.0641 99.485L5.77638 96.4355Z"
                    fill="#F970A1"
                />
                <path
                    d="M5.44915 99.3449C6.74514 99.3449 7.79575 98.2955 7.79575 97.0011C7.79575 95.7066 6.74514 94.6572 5.44915 94.6572C4.15315 94.6572 3.10254 95.7066 3.10254 97.0011C3.10254 98.2955 4.15315 99.3449 5.44915 99.3449Z"
                    fill="#F970A1"
                />
                <path
                    d="M2.74902 106.438L2.74902 107.73L8.85499 107.73L8.85499 106.438L2.74902 106.438Z"
                    fill="#F970A1"
                />
                <path
                    d="M2.74309 109.438C4.03909 109.438 5.0897 108.389 5.0897 107.094C5.0897 105.8 4.03909 104.75 2.74309 104.75C1.4471 104.75 0.396484 105.8 0.396484 107.094C0.396484 108.389 1.4471 109.438 2.74309 109.438Z"
                    fill="#F970A1"
                />
                <path
                    d="M5.12934 116.607L5.77588 117.726L11.0637 114.677L10.4172 113.558L5.12934 116.607Z"
                    fill="#F970A1"
                />
                <path
                    d="M5.44866 119.507C6.74465 119.507 7.79527 118.457 7.79527 117.163C7.79527 115.868 6.74465 114.819 5.44866 114.819C4.15266 114.819 3.10205 115.868 3.10205 117.163C3.10205 118.457 4.15266 119.507 5.44866 119.507Z"
                    fill="#F970A1"
                />
                <path
                    d="M12.2845 124.23L13.4043 124.875L16.4573 119.594L15.3376 118.948L12.2845 124.23Z"
                    fill="#F970A1"
                />
                <path
                    d="M13.3748 125.199C14.2609 125.199 14.9791 124.482 14.9791 123.597C14.9791 122.712 14.2609 121.995 13.3748 121.995C12.4888 121.995 11.7705 122.712 11.7705 123.597C11.7705 124.482 12.4888 125.199 13.3748 125.199Z"
                    fill="#F970A1"
                />
                <path
                    d="M22.29 127.252L23.583 127.252L23.583 121.153L22.29 121.153L22.29 127.252Z"
                    fill="#F970A1"
                />
                <path
                    d="M22.9526 129.6C24.2486 129.6 25.2992 128.55 25.2992 127.256C25.2992 125.961 24.2486 124.912 22.9526 124.912C21.6566 124.912 20.606 125.961 20.606 127.256C20.606 128.55 21.6566 129.6 22.9526 129.6Z"
                    fill="#F970A1"
                />
                <path
                    d="M32.4721 124.884L33.5918 124.238L30.539 118.956L29.4193 119.602L32.4721 124.884Z"
                    fill="#F970A1"
                />
                <path
                    d="M33.0331 126.897C34.3291 126.897 35.3797 125.848 35.3797 124.553C35.3797 123.259 34.3291 122.209 33.0331 122.209C31.7371 122.209 30.6865 123.259 30.6865 124.553C30.6865 125.848 31.7371 126.897 33.0331 126.897Z"
                    fill="#F970A1"
                />
                <path
                    d="M38.9287 117.183L39.5752 116.064L34.2875 113.015L33.641 114.133L38.9287 117.183Z"
                    fill="#F970A1"
                />
                <path
                    d="M39.2591 118.263C40.1584 118.263 40.8874 117.535 40.8874 116.637C40.8874 115.738 40.1584 115.01 39.2591 115.01C38.3599 115.01 37.6309 115.738 37.6309 116.637C37.6309 117.535 38.3599 118.263 39.2591 118.263Z"
                    fill="#F970A1"
                />
                <path
                    d="M43.1406 107.746L43.1406 106.454L37.0347 106.454L37.0347 107.746L43.1406 107.746Z"
                    fill="#F970A1"
                />
                <path
                    d="M43.1386 109.438C44.4346 109.438 45.4852 108.389 45.4852 107.094C45.4852 105.8 44.4346 104.75 43.1386 104.75C41.8426 104.75 40.792 105.8 40.792 107.094C40.792 108.389 41.8426 109.438 43.1386 109.438Z"
                    fill="#F970A1"
                />
                <path
                    d="M40.7676 97.5667L40.1211 96.4482L34.8333 99.4975L35.4798 100.616L40.7676 97.5667Z"
                    fill="#F970A1"
                />
                <path
                    d="M39.1397 99.6319C40.1844 99.6319 41.0314 98.786 41.0314 97.7425C41.0314 96.699 40.1844 95.853 39.1397 95.853C38.095 95.853 37.248 96.699 37.248 97.7425C37.248 98.786 38.095 99.6319 39.1397 99.6319Z"
                    fill="#F970A1"
                />
                <path
                    d="M33.6129 89.9409L32.4932 89.2952L29.4401 94.5767L30.5599 95.2224L33.6129 89.9409Z"
                    fill="#F970A1"
                />
                <path
                    d="M33.0336 91.9548C34.3296 91.9548 35.3802 90.9054 35.3802 89.6109C35.3802 88.3165 34.3296 87.2671 33.0336 87.2671C31.7376 87.2671 30.687 88.3165 30.687 89.6109C30.687 90.9054 31.7376 91.9548 33.0336 91.9548Z"
                    fill="#F970A1"
                />
                <path
                    d="M32.1956 103.507C33.0552 103.507 33.752 102.811 33.752 101.952C33.752 101.093 33.0552 100.397 32.1956 100.397C31.336 100.397 30.6392 101.093 30.6392 101.952C30.6392 102.811 31.336 103.507 32.1956 103.507Z"
                    fill="#AB4C6F"
                />
                <path
                    d="M35.0926 106.616C35.9521 106.616 36.649 105.92 36.649 105.061C36.649 104.203 35.9521 103.507 35.0926 103.507C34.233 103.507 33.5361 104.203 33.5361 105.061C33.5361 105.92 34.233 106.616 35.0926 106.616Z"
                    fill="#AB4C6F"
                />
                <path
                    d="M31.6689 107.022C32.2904 107.022 32.7943 106.519 32.7943 105.898C32.7943 105.277 32.2904 104.774 31.6689 104.774C31.0473 104.774 30.5435 105.277 30.5435 105.898C30.5435 106.519 31.0473 107.022 31.6689 107.022Z"
                    fill="#AB4C6F"
                />
                <path
                    d="M102.704 162.799C111.231 172.105 125.679 172.744 134.974 164.227C144.269 155.71 144.892 141.262 136.365 131.956C127.837 122.649 113.39 122.01 104.094 130.527C94.7991 139.044 94.1765 153.493 102.704 162.799Z"
                    fill="#29ABE2"
                />
                <path
                    d="M141.994 127.934L140.773 126.683L134.121 132.872L135.341 134.122L141.994 127.934Z"
                    fill="#29ABE2"
                />
                <path
                    d="M138.75 129.867C139.995 131.178 142.17 131.128 143.608 129.756C145.045 128.383 145.201 126.208 143.956 124.898C142.711 123.587 140.536 123.637 139.098 125.009C137.661 126.382 137.505 128.557 138.75 129.867Z"
                    fill="#29ABE2"
                />
                <path
                    d="M129.328 119.302L127.625 118.852L124.835 127.504L126.539 127.954L129.328 119.302Z"
                    fill="#29ABE2"
                />
                <path
                    d="M125.662 123.222C126.666 124.278 128.42 124.238 129.579 123.131C130.737 122.025 130.863 120.272 129.859 119.215C128.855 118.159 127.102 118.199 125.943 119.305C124.784 120.412 124.659 122.165 125.662 123.222Z"
                    fill="#29ABE2"
                />
                <path
                    d="M114.176 118.253L112.193 118.791L114.001 127.398L115.984 126.859L114.176 118.253Z"
                    fill="#29ABE2"
                />
                <path
                    d="M110.504 120.944C111.749 122.255 113.924 122.205 115.362 120.832C116.799 119.46 116.955 117.285 115.71 115.974C114.465 114.664 112.29 114.714 110.852 116.086C109.414 117.458 109.259 119.634 110.504 120.944Z"
                    fill="#29ABE2"
                />
                <path
                    d="M100.297 124.887L98.835 126.317L104.816 132.767L106.279 131.338L100.297 124.887Z"
                    fill="#29ABE2"
                />
                <path
                    d="M96.914 128.016C98.1593 129.327 100.334 129.277 101.772 127.904C103.21 126.532 103.365 124.357 102.12 123.046C100.875 121.736 98.6998 121.786 97.2622 123.158C95.8246 124.53 95.6687 126.705 96.914 128.016Z"
                    fill="#29ABE2"
                />
                <path
                    d="M91.6513 137.535L91.0283 139.615L99.5076 142.038L100.131 139.958L91.6513 137.535Z"
                    fill="#29ABE2"
                />
                <path
                    d="M88.7089 140.916C89.9542 142.226 92.1292 142.176 93.5668 140.804C95.0044 139.431 95.1603 137.256 93.915 135.946C92.6697 134.635 90.4947 134.685 89.0571 136.057C87.6195 137.43 87.4636 139.605 88.7089 140.916Z"
                    fill="#29ABE2"
                />
                <path
                    d="M90.4442 153.094L90.8037 154.667L99.5883 152.46L99.2288 150.886L90.4442 153.094Z"
                    fill="#29ABE2"
                />
                <path
                    d="M90.4286 155.068C91.28 155.964 92.7669 155.93 93.7498 154.992C94.7327 154.053 94.8393 152.566 93.9879 151.67C93.1365 150.774 91.6495 150.808 90.6666 151.747C89.6838 152.685 89.5772 154.172 90.4286 155.068Z"
                    fill="#29ABE2"
                />
                <path
                    d="M97.0871 166.773L98.3076 168.024L104.96 161.836L103.74 160.585L97.0871 166.773Z"
                    fill="#29ABE2"
                />
                <path
                    d="M95.1367 169.831C96.382 171.142 98.5569 171.092 99.9945 169.719C101.432 168.347 101.588 166.172 100.343 164.861C99.0974 163.55 96.9225 163.6 95.4849 164.973C94.0472 166.345 93.8913 168.52 95.1367 169.831Z"
                    fill="#29ABE2"
                />
                <path
                    d="M109.733 175.408L111.437 175.858L114.226 167.206L112.522 166.756L109.733 175.408Z"
                    fill="#29ABE2"
                />
                <path
                    d="M108.042 178.052C109.287 179.363 111.462 179.313 112.9 177.94C114.337 176.568 114.493 174.393 113.248 173.082C112.003 171.771 109.828 171.822 108.39 173.194C106.953 174.566 106.797 176.741 108.042 178.052Z"
                    fill="#29ABE2"
                />
                <path
                    d="M124.321 174.639L126.303 174.101L124.495 165.494L122.513 166.032L124.321 174.639Z"
                    fill="#29ABE2"
                />
                <path
                    d="M123.565 176.18C124.429 177.089 125.938 177.054 126.936 176.102C127.933 175.15 128.041 173.641 127.177 172.731C126.313 171.822 124.804 171.856 123.807 172.809C122.809 173.761 122.701 175.27 123.565 176.18Z"
                    fill="#29ABE2"
                />
                <path
                    d="M138.759 169.845L140.221 168.416L134.24 161.966L132.777 163.395L138.759 169.845Z"
                    fill="#29ABE2"
                />
                <path
                    d="M136.924 171.682C138.169 172.993 140.344 172.943 141.782 171.57C143.219 170.198 143.375 168.023 142.13 166.712C140.885 165.401 138.71 165.451 137.272 166.824C135.834 168.196 135.678 170.371 136.924 171.682Z"
                    fill="#29ABE2"
                />
                <path
                    d="M147.423 157.198L148.046 155.118L139.567 152.695L138.944 154.775L147.423 157.198Z"
                    fill="#29ABE2"
                />
                <path
                    d="M143.576 157.615C144.58 158.671 146.333 158.631 147.492 157.525C148.651 156.418 148.777 154.665 147.773 153.608C146.769 152.552 145.016 152.592 143.857 153.698C142.698 154.805 142.572 156.558 143.576 157.615Z"
                    fill="#29ABE2"
                />
                <path
                    d="M148.635 141.634L148.275 140.06L139.491 142.268L139.85 143.841L148.635 141.634Z"
                    fill="#29ABE2"
                />
                <path
                    d="M145.814 143.442C147.06 144.753 149.235 144.703 150.672 143.33C152.11 141.958 152.266 139.783 151.02 138.472C149.775 137.162 147.6 137.212 146.163 138.584C144.725 139.956 144.569 142.131 145.814 143.442Z"
                    fill="#29ABE2"
                />
                <path
                    d="M132.52 153.923C133.346 154.792 134.788 154.759 135.742 153.848C136.695 152.938 136.799 151.496 135.973 150.626C135.147 149.757 133.704 149.79 132.751 150.7C131.797 151.611 131.694 153.053 132.52 153.923Z"
                    fill="#1B6F94"
                />
                <path
                    d="M129.973 160.134C130.799 161.004 132.241 160.971 133.195 160.06C134.148 159.15 134.252 157.707 133.426 156.838C132.6 155.969 131.157 156.002 130.204 156.912C129.25 157.823 129.147 159.265 129.973 160.134Z"
                    fill="#1B6F94"
                />
                <path
                    d="M126.154 156.806C126.751 157.435 127.794 157.411 128.484 156.753C129.173 156.094 129.248 155.051 128.651 154.423C128.053 153.794 127.01 153.818 126.321 154.476C125.631 155.134 125.557 156.178 126.154 156.806Z"
                    fill="#1B6F94"
                />
            </svg>
        );
    }
    else if (props.img === "donation-radio") {
        return(
            <svg
                width="23"
                height="18"
                viewBox="0 0 23 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 17C0 17.5523 0.447715 18 1 18H22C22.5523 18 23 17.5523 23 17V7C23 6.44772 22.5523 6 22 6H1C0.447715 6 0 6.44772 0 7V17ZM14.5 12C14.5 13.6569 13.1569 15 11.5 15C9.84315 15 8.5 13.6569 8.5 12C8.5 10.3431 9.84315 9 11.5 9C13.1569 9 14.5 10.3431 14.5 12ZM13.5 12C13.5 13.1046 12.6046 14 11.5 14C10.3954 14 9.5 13.1046 9.5 12C9.5 10.8954 10.3954 10 11.5 10C12.6046 10 13.5 10.8954 13.5 12Z"
                    fill="#46C0B8"
                />
                <path d="M0 4L23 3V5H0V4Z" fill="#46C0B8" />
                <path d="M0 2L23 0V2L0 3V2Z" fill="#46C0B8" />
            </svg>
        )
    }
    else if (props.img === 'corona-pink') {
        return (
            <svg viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M56.1744 94.8836C77.6147 94.8836 94.9955 77.5233 94.9955 56.1082C94.9955 34.6931 77.6147 17.3328 56.1744 17.3328C34.7341 17.3328 17.3533 34.6931 17.3533 56.1082C17.3533 77.5233 34.7341 94.8836 56.1744 94.8836Z"
                    fill="#F92672"
                />
                <path
                    d="M57.7844 5.83716H54.5642V21.0257H57.7844V5.83716Z"
                    fill="#F92672"
                />
                <path
                    d="M56.1744 11.6743C59.4019 11.6743 62.0184 9.06093 62.0184 5.83716C62.0184 2.61339 59.4019 0 56.1744 0C52.9468 0 50.3303 2.61339 50.3303 5.83716C50.3303 9.06093 52.9468 11.6743 56.1744 11.6743Z"
                    fill="#F92672"
                />
                <path
                    d="M32.4068 11.7611L29.6182 13.3693L37.2213 26.5226L40.01 24.9144L32.4068 11.7611Z"
                    fill="#F92672"
                />
                <path
                    d="M32.8577 20.4896C35.4596 20.4896 37.5688 18.3829 37.5688 15.7841C37.5688 13.1854 35.4596 11.0787 32.8577 11.0787C30.2559 11.0787 28.1467 13.1854 28.1467 15.7841C28.1467 18.3829 30.2559 20.4896 32.8577 20.4896Z"
                    fill="#F92672"
                />
                <path
                    d="M13.3977 29.564L11.7876 32.3494L24.9564 39.9437L26.5665 37.1583L13.3977 29.564Z"
                    fill="#F92672"
                />
                <path
                    d="M12.5826 36.8098C15.8101 36.8098 18.4266 34.1964 18.4266 30.9727C18.4266 27.7489 15.8101 25.1355 12.5826 25.1355C9.35499 25.1355 6.73853 27.7489 6.73853 30.9727C6.73853 34.1964 9.35499 36.8098 12.5826 36.8098Z"
                    fill="#F92672"
                />
                <path
                    d="M5.85864 54.4748L5.85864 57.6912L21.0651 57.6912L21.0651 54.4748L5.85864 54.4748Z"
                    fill="#F92672"
                />
                <path
                    d="M5.84404 61.9453C9.07161 61.9453 11.6881 59.332 11.6881 56.1082C11.6881 52.8844 9.07161 50.271 5.84404 50.271C2.61646 50.271 0 52.8844 0 56.1082C0 59.332 2.61646 61.9453 5.84404 61.9453Z"
                    fill="#F92672"
                />
                <path
                    d="M11.7874 79.8007L13.3975 82.5861L26.5662 74.9918L24.9561 72.2064L11.7874 79.8007Z"
                    fill="#F92672"
                />
                <path
                    d="M12.5826 87.0213C15.8101 87.0213 18.4266 84.4079 18.4266 81.1842C18.4266 77.9604 15.8101 75.347 12.5826 75.347C9.35499 75.347 6.73853 77.9604 6.73853 81.1842C6.73853 84.4079 9.35499 87.0213 12.5826 87.0213Z"
                    fill="#F92672"
                />
                <path
                    d="M29.6061 98.7825L32.3948 100.391L39.998 87.2375L37.2093 85.6293L29.6061 98.7825Z"
                    fill="#F92672"
                />
                <path
                    d="M32.3211 101.197C34.5277 101.197 36.3165 99.4106 36.3165 97.2066C36.3165 95.0025 34.5277 93.2158 32.3211 93.2158C30.1145 93.2158 28.3257 95.0025 28.3257 97.2066C28.3257 99.4106 30.1145 101.197 32.3211 101.197Z"
                    fill="#F92672"
                />
                <path
                    d="M54.5245 106.31L57.7446 106.31L57.7446 91.122L54.5245 91.122L54.5245 106.31Z"
                    fill="#F92672"
                />
                <path
                    d="M56.1744 112.157C59.4019 112.157 62.0184 109.543 62.0184 106.32C62.0184 103.096 59.4019 100.483 56.1744 100.483C52.9468 100.483 50.3303 103.096 50.3303 106.32C50.3303 109.543 52.9468 112.157 56.1744 112.157Z"
                    fill="#F92672"
                />
                <path
                    d="M79.8825 100.411L82.6711 98.8024L75.068 85.6491L72.2793 87.2573L79.8825 100.411Z"
                    fill="#F92672"
                />
                <path
                    d="M81.2801 105.426C84.5076 105.426 87.1241 102.813 87.1241 99.5891C87.1241 96.3653 84.5076 93.7519 81.2801 93.7519C78.0525 93.7519 75.436 96.3653 75.436 99.5891C75.436 102.813 78.0525 105.426 81.2801 105.426Z"
                    fill="#F92672"
                />
                <path
                    d="M95.9617 81.2337L97.5718 78.4483L84.403 70.854L82.7929 73.6394L95.9617 81.2337Z"
                    fill="#F92672"
                />
                <path
                    d="M96.7843 83.9241C99.0238 83.9241 100.839 82.1107 100.839 79.8738C100.839 77.6369 99.0238 75.8235 96.7843 75.8235C94.5448 75.8235 92.7292 77.6369 92.7292 79.8738C92.7292 82.1107 94.5448 83.9241 96.7843 83.9241Z"
                    fill="#F92672"
                />
                <path
                    d="M106.45 57.7309L106.45 54.5145L91.2438 54.5145L91.2438 57.7309L106.45 57.7309Z"
                    fill="#F92672"
                />
                <path
                    d="M106.445 61.9453C109.673 61.9453 112.289 59.332 112.289 56.1082C112.289 52.8844 109.673 50.271 106.445 50.271C103.218 50.271 100.601 52.8844 100.601 56.1082C100.601 59.332 103.218 61.9453 106.445 61.9453Z"
                    fill="#F92672"
                />
                <path
                    d="M100.541 32.381L98.9309 29.5956L85.7621 37.1898L87.3722 39.9752L100.541 32.381Z"
                    fill="#F92672"
                />
                <path
                    d="M96.4862 37.5246C99.088 37.5246 101.197 35.4179 101.197 32.8191C101.197 30.2204 99.088 28.1136 96.4862 28.1136C93.8843 28.1136 91.7751 30.2204 91.7751 32.8191C91.7751 35.4179 93.8843 37.5246 96.4862 37.5246Z"
                    fill="#F92672"
                />
                <path
                    d="M82.7225 13.3892L79.9338 11.781L72.3306 24.9343L75.1193 26.5425L82.7225 13.3892Z"
                    fill="#F92672"
                />
                <path
                    d="M81.2798 18.4049C84.5074 18.4049 87.1239 15.7915 87.1239 12.5678C87.1239 9.34398 84.5074 6.73059 81.2798 6.73059C78.0523 6.73059 75.4358 9.34398 75.4358 12.5678C75.4358 15.7915 78.0523 18.4049 81.2798 18.4049Z"
                    fill="#F92672"
                />
                <path
                    d="M79.1926 47.1738C81.3333 47.1738 83.0687 45.4404 83.0687 43.3022C83.0687 41.164 81.3333 39.4306 79.1926 39.4306C77.0518 39.4306 75.3164 41.164 75.3164 43.3022C75.3164 45.4404 77.0518 47.1738 79.1926 47.1738Z"
                    fill="#9B365B"
                />
                <path
                    d="M86.4084 54.9169C88.5491 54.9169 90.2845 53.1836 90.2845 51.0453C90.2845 48.9071 88.5491 47.1738 86.4084 47.1738C84.2676 47.1738 82.5322 48.9071 82.5322 51.0453C82.5322 53.1836 84.2676 54.9169 86.4084 54.9169Z"
                    fill="#9B365B"
                />
                <path
                    d="M77.8809 55.9295C79.4288 55.9295 80.6836 54.6762 80.6836 53.1301C80.6836 51.584 79.4288 50.3306 77.8809 50.3306C76.333 50.3306 75.0781 51.584 75.0781 53.1301C75.0781 54.6762 76.333 55.9295 77.8809 55.9295Z"
                    fill="#9B365B"
                />
            </svg>
        );
    }
    else if (props.img === 'corona-small') {
        return (
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.89432 15.0235C12.2891 15.0235 15.0411 12.2747 15.0411 8.88393C15.0411 5.49316 12.2891 2.7444 8.89432 2.7444C5.49956 2.7444 2.74756 5.49316 2.74756 8.88393C2.74756 12.2747 5.49956 15.0235 8.89432 15.0235Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M9.14927 0.924225H8.6394V3.32911H9.14927V0.924225Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M8.89456 1.84846C9.4056 1.84846 9.81988 1.43467 9.81988 0.92423C9.81988 0.413792 9.4056 0 8.89456 0C8.38352 0 7.96924 0.413792 7.96924 0.92423C7.96924 1.43467 8.38352 1.84846 8.89456 1.84846Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M5.13124 1.8622L4.6897 2.11684L5.89355 4.19947L6.3351 3.94484L5.13124 1.8622Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M5.20246 3.24423C5.61442 3.24423 5.94839 2.91067 5.94839 2.49919C5.94839 2.08772 5.61442 1.75415 5.20246 1.75415C4.7905 1.75415 4.45654 2.08772 4.45654 2.49919C4.45654 2.91067 4.7905 3.24423 5.20246 3.24423Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M2.12163 4.68103L1.8667 5.12206L3.95179 6.3245L4.20672 5.88347L2.12163 4.68103Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M1.99221 5.8283C2.50325 5.8283 2.91753 5.41451 2.91753 4.90407C2.91753 4.39363 2.50325 3.97984 1.99221 3.97984C1.48117 3.97984 1.06689 4.39363 1.06689 4.90407C1.06689 5.41451 1.48117 5.8283 1.99221 5.8283Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M0.927734 8.6253L0.927734 9.13457L3.33545 9.13457L3.33545 8.6253L0.927734 8.6253Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M0.925319 9.80815C1.43636 9.80815 1.85064 9.39436 1.85064 8.88392C1.85064 8.37348 1.43636 7.95969 0.925319 7.95969C0.41428 7.95969 0 8.37348 0 8.88392C0 9.39436 0.41428 9.80815 0.925319 9.80815Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M1.8664 12.6353L2.12134 13.0763L4.20642 11.8739L3.95149 11.4329L1.8664 12.6353Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M1.99221 13.7786C2.50325 13.7786 2.91753 13.3648 2.91753 12.8544C2.91753 12.3439 2.50325 11.9301 1.99221 11.9301C1.48117 11.9301 1.06689 12.3439 1.06689 12.8544C1.06689 13.3648 1.48117 13.7786 1.99221 13.7786Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M4.68785 15.6408L5.12939 15.8954L6.33326 13.8128L5.89171 13.5582L4.68785 15.6408Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M5.11772 16.0231C5.46711 16.0231 5.75034 15.7402 5.75034 15.3913C5.75034 15.0423 5.46711 14.7594 5.11772 14.7594C4.76834 14.7594 4.48511 15.0423 4.48511 15.3913C4.48511 15.7402 4.76834 16.0231 5.11772 16.0231Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M8.6332 16.8327L9.14307 16.8327L9.14307 14.4278L8.6332 14.4278L8.6332 16.8327Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M8.89456 17.7584C9.4056 17.7584 9.81988 17.3446 9.81988 16.8342C9.81988 16.3238 9.4056 15.91 8.89456 15.91C8.38352 15.91 7.96924 16.3238 7.96924 16.8342C7.96924 17.3446 8.38352 17.7584 8.89456 17.7584Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M12.6483 15.8986L13.0898 15.6439L11.886 13.5613L11.4444 13.8159L12.6483 15.8986Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M12.8694 16.6927C13.3805 16.6927 13.7947 16.2789 13.7947 15.7685C13.7947 15.2581 13.3805 14.8443 12.8694 14.8443C12.3584 14.8443 11.9441 15.2581 11.9441 15.7685C11.9441 16.2789 12.3584 16.6927 12.8694 16.6927Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M15.194 12.8622L15.449 12.4212L13.3639 11.2187L13.109 11.6597L15.194 12.8622Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M15.3244 13.2882C15.679 13.2882 15.9665 13.001 15.9665 12.6469C15.9665 12.2927 15.679 12.0056 15.3244 12.0056C14.9698 12.0056 14.6824 12.2927 14.6824 12.6469C14.6824 13.001 14.9698 13.2882 15.3244 13.2882Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M16.855 9.14088L16.855 8.63161L14.4473 8.63161L14.4473 9.14088L16.855 9.14088Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M16.854 9.80816C17.3651 9.80816 17.7794 9.39437 17.7794 8.88393C17.7794 8.3735 17.3651 7.9597 16.854 7.9597C16.343 7.9597 15.9287 8.3735 15.9287 8.88393C15.9287 9.39437 16.343 9.80816 16.854 9.80816Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M15.9192 5.12706L15.6643 4.68604L13.5792 5.88847L13.8342 6.3295L15.9192 5.12706Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M15.2772 5.94148C15.6891 5.94148 16.0231 5.60792 16.0231 5.19644C16.0231 4.78497 15.6891 4.4514 15.2772 4.4514C14.8652 4.4514 14.5312 4.78497 14.5312 5.19644C14.5312 5.60792 14.8652 5.94148 15.2772 5.94148Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M13.0978 2.11999L12.6562 1.86536L11.4524 3.94799L11.8939 4.20262L13.0978 2.11999Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M12.8694 2.91415C13.3805 2.91415 13.7947 2.50036 13.7947 1.98992C13.7947 1.47948 13.3805 1.06569 12.8694 1.06569C12.3584 1.06569 11.9441 1.47948 11.9441 1.98992C11.9441 2.50036 12.3584 2.91415 12.8694 2.91415Z"
                    fill="#E8DDE1"
                />
                <path
                    d="M12.539 7.4693C12.878 7.4693 13.1528 7.19485 13.1528 6.8563C13.1528 6.51774 12.878 6.24329 12.539 6.24329C12.2001 6.24329 11.9253 6.51774 11.9253 6.8563C11.9253 7.19485 12.2001 7.4693 12.539 7.4693Z"
                    fill="#C2AEB5"
                />
                <path
                    d="M13.6816 8.69532C14.0206 8.69532 14.2953 8.42086 14.2953 8.08231C14.2953 7.74375 14.0206 7.4693 13.6816 7.4693C13.3426 7.4693 13.0679 7.74375 13.0679 8.08231C13.0679 8.42086 13.3426 8.69532 13.6816 8.69532Z"
                    fill="#C2AEB5"
                />
                <path
                    d="M12.3312 8.85562C12.5763 8.85562 12.775 8.65717 12.775 8.41237C12.775 8.16757 12.5763 7.96912 12.3312 7.96912C12.0861 7.96912 11.8875 8.16757 11.8875 8.41237C11.8875 8.65717 12.0861 8.85562 12.3312 8.85562Z"
                    fill="#C2AEB5"
                />
            </svg>
        );
    }
    else if(props.img === "cheveron-up") {return(
        <svg
            className="svg-icon"
            width="9"
            height="5"
            viewBox="0 0 9 5"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.5 0L9 5H0L4.5 0Z"
                fill="#F0F8FF"
            />
        </svg>
    )}
    else if (props.img === "cheveron-right") {
        return(
            <svg
                className="svg-icon"
                width="11"
                height="16"
                viewBox="0 0 11 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M10.0268 9.56642C11.0355 8.7657 11.0355 7.2343 10.0268 6.43358L3.24351 1.0486C1.93279 0.00808144 0 0.941507 0 2.61503L0 13.385C0 15.0585 1.9328 15.9919 3.24351 14.9514L10.0268 9.56642Z" />
            </svg>
        )
    }
    else if(props.img === "location-icon") {
        return ( <svg
            className="svg-icon"
            width="10"
            height="10"
            viewBox="0 0 7 10"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.1403 9.79824C0.491641 5.6842 0 5.26197 0 3.75C0 1.67893 1.567 0 3.5 0C5.433 0 7 1.67893 7 3.75C7 5.26197 6.50836 5.6842 3.8597 9.79824C3.68588 10.0673 3.3141 10.0672 3.1403 9.79824ZM3.5 5.3125C4.30542 5.3125 4.95833 4.61295 4.95833 3.75C4.95833 2.88705 4.30542 2.1875 3.5 2.1875C2.69458 2.1875 2.04167 2.88705 2.04167 3.75C2.04167 4.61295 2.69458 5.3125 3.5 5.3125Z"
                fill="white"
            />
        </svg>)
    }
    else if(props.img === "envelope") {
        return(
            <svg
                width="123"
                height="83"
                viewBox="0 0 123 83"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="3.98169"
                    y="20.0704"
                    width="105.312"
                    height="62.2719"
                    transform="rotate(-10.575 3.98169 20.0704)"
                    fill="#DBF4FF"
                />
                <path
                    opacity="0.3"
                    d="M13.0316 79.9523C12.47 80.6793 13.1035 81.7152 14.0065 81.5466L120.337 61.6955C121.24 61.5269 121.457 60.3322 120.671 59.8568L60.9401 23.7315C59.2001 22.6791 56.9475 23.0997 55.7045 24.7089L13.0316 79.9523Z"
                    fill="#AEE6FF"
                />
                <path
                    d="M3.51551 21.9256C2.74024 21.444 2.9625 20.2607 3.85972 20.0932L107.627 0.7206C108.524 0.553098 109.158 1.57645 108.609 2.3054L67.229 57.2235C65.9832 58.8769 63.6818 59.3065 61.9234 58.2141L3.51551 21.9256Z"
                    fill="#29ABE2"
                />
            </svg>
        )
    }
}

export default Svg;
