import React, { Component } from 'react';
import CommitteeDetail from './committeeDetail';

class Committee extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let committees = Object.keys(this.props.committees);
    let subDomainData = this.props.subDomainData;
    return (
      <div className="mun-data" id="committee">
          {committees !== undefined && (
            <h3 className="heading__secondary">
              {subDomainData.title} अन्तर्गतका समितिकाे विवरण
            </h3>
          )}
       
        {committees !== undefined && (
          <div>
            {committees.map((committee, key) => (
              <CommitteeDetail
                key={key}
                committee={this.props.committees[committee]}
                title={committee}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default Committee;
