import * as Tabletop from 'tabletop';

class CovidInfoService {

  async fetchNationalInfo() {
    let self=this;
    try {

      const data = new Promise((resolve, reject) => {
        Tabletop.init({
          key: 'https://docs.google.com/spreadsheets/d/14hG33UMtE-nk3JihfBp8al5sy2RmphQi1b1t5Qm4PKM/edit?usp=sharing',
          callback: function(data, tabletop) {
            const sheetData = {
              national_stats: self.getElements(data, 'national_stats'),
            };

            resolve(sheetData);
          },
          simpleSheet: false,
        });
      });

      return await data;
    }
    catch (error) {
      return error;
    }
  }

  async fetchCOVIDInfo(subDomainData) {
    let self = this;
    const data = new Promise((resolve, reject) => {
      Tabletop.init({
        key: subDomainData.covidUrl,
        callback: function(data, tabletop) {
          const sheetData = {
            message_from_mayor: self.getElements(data, 'message_from_mayor'),
            important_centers: self.getElements(data, 'important_centers'),
            donor_help: self.getElements(data, 'donor_help'),
            major_activities: self.getElements(data, 'major_activities'),
            programs: self.getElements(data, 'programs'),
            contact_details: self.getElements(data, 'contact_details'),
            committee: self.getElements(data, 'committee'),
            form_links: self.getElements(data, 'form_links'),
            local_stats: self.getElements(data, 'local_stats'),
            podcast_links: self.getElements(data, 'podcast_links'),
            national_stats: self.getElements(data, 'national_stats'),
            ward_representatives: self.getElements(data, 'ward_representatives'),
          };

          resolve(sheetData);
        },
        simpleSheet: false,
      });
    });

    return await data;
  }

  shiftArray(elements) {
    elements.shift();
    return elements;
  }

  getElements(data, key) {
    if (data[key] === undefined) {
      return [];
    }
    return this.shiftArray(data[key].elements);
  }
}

export default new CovidInfoService();
