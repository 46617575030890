import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { media } from "../../components/styled/utils";

const Modal = ({
  isOpen,
  onRequestClose,
  shouldCloseOnOverlayClick,
  children,
  handleClose,
  className,
}) => {
  return (
    <ReactModal
      className="contact-popup"
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      className={className}
    >
      {/*
      <Header>
        <CloseIcon onClick={handleClose} />
      </Header>
      */}
      <Body>{children}</Body>
    </ReactModal>
  );
};
export default Modal;

const Header = styled.div`
  background: #fff;
  position: relative;
  padding: 28px 18px;
  border-radius: 8px 8px 0px 0px;
  font-size: ${(props) => props.theme.fontSize.md}px;
  ${media.tablet`
    padding: 40px 48px;
  `};
`;

const Body = styled.div`
  padding: 0 18px 18px 18px;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  max-height: 75vh;
  position: relative;

  ${media.tablet`
  padding: 24px 32px;
  `};
`;

const CloseIcon = styled.span`
    border-radius: 50%;
    background-color: rgba(22, 45, 61, 0.2);
  	display: block;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 48px;
    opacity: 0.6;
    cursor: pointer;
    transition: all .2s ease-in;

    &:hover{
       background-color: rgba(22, 45, 61, 0.5);
    }

    &:before,&:after{
    	content: '';
    height: 12px;
    width: 2px;
    background: #000e1a;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 6px;
    }

    &:before{
    transform: translate(-50%,-50%) rotate(-45deg);
    }

    &:after{
    transform: translate(-50%,-50%) rotate(45deg);
    }
}
`;
