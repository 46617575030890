import React, { Component } from "react";
import Svg from "./../svg";

class DonationLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let formLinks = this.props.formLinks;
    return (
      <section className="help ">
        {formLinks.length > 0 && (
          <div className="container items-center">
            <a
              href={formLinks[0].donation_link}
              target="_blank"
              className="card help__card rounded text-center"
            >
              <span className="help__card-envelope">
                <Svg img="envelope"/>
              </span>
              <span className="help__card-smaller-text">
                के तपाईं सहायता गर्न चाहनुहुन्छ?
              </span>
              <span className="help__card-bigger-text">
                सहायता लागी सम्पर्क गर्नुहोस{" "}
              </span>
            </a>
          </div>
        )}
      </section>
    );
  }
}
export default DonationLink;
